import React from 'react'
import CustomButton from '../Buttons/CustomButton'
import PauseCircleOutlineRoundedIcon from '@material-ui/icons/PauseCircleOutlineRounded'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Data from '../../services/Data'

const Timer = ({ stopHandler }) => {
  const timerData = useSelector(state => state.TimerReducer)
  const dataSet = Data()

  const secondsToTime = time => {
    let timeMinutes = 0
    let timeSeconds
    if (time < 60) {
      timeSeconds = time
    } else {
      timeMinutes = Math.floor(time / 60)
      timeSeconds = time - timeMinutes * 60
    }

    if (timeSeconds < 10) {
      timeSeconds = `0${timeSeconds.toString()}`
    }

    if (timeMinutes < 10) {
      timeMinutes = `0${timeMinutes.toString()}`
    }

    return `${timeMinutes}:${timeSeconds}`
  }

  const stopTimer = () => {
    stopHandler()
    dataSet.stopJob()
  }

  return (
      <CustomButton action={stopTimer} title='Stop' width='190px'>
        <div style={{ fontSize: 16, display: 'inline-flex' }}>
          <PauseCircleOutlineRoundedIcon style={{ color: '#44BD7E', marginRight: 12 }}/>
          {secondsToTime(timerData.seconds)}
        </div>
      </CustomButton>
  )
}

Timer.propTypes = {
  stopHandler: PropTypes.func
}

export default React.memo(Timer, (props, nextProps) => {
  return JSON.stringify(props) === JSON.stringify(nextProps)
})
