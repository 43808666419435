import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import FormControl from '@material-ui/core/FormControl'
import CustomButton from '../Buttons/CustomButton'
import Popover from '@material-ui/core/Popover'
import { setFilters } from '../../redux/actions/FiltersActions'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  modalFilters: {
    padding: theme.spacing(3)
  },
  oneFilter: {
    width: theme.components.filtersPopover.width,
    marginRight: theme.spacing(3)
  },
  lastFilter: {
    marginRight: theme.spacing(0)
  },
  controllButtons: {
    marginTop: theme.spacing(4)
  },
  indicator: {
    width: theme.components.filtersPopover.indicatorSize,
    height: theme.components.filtersPopover.indicatorSize,
    backgroundColor: theme.components.filtersPopover.indicatorColor,
    borderRadius: theme.components.filtersPopover.indicatorBorderRadius,
    border: '4px solid #F5F5F5',
    position: 'absolute',
    right: theme.components.filtersPopover.indicatorPosition,
    top: theme.components.filtersPopover.indicatorPosition
  },
  buttonFilters: {
    backgroundColor: 'white',
    borderRadius: '4px 4px 0 0',
    boxShadow: '0px 0px 5px -1px #000000'
  },
  title: {
    marginBottom: theme.spacing(3)
  }
}))

function FilterAndExport ({ exportFunc, filterType = 'output' }) {
  const classes = useStyles()

  const filtersData = useSelector(state => state.FilterReducer)
  const dispatch = useDispatch()

  const filters = filtersData.filters

  const [anchorEl, setAnchorEl] = useState(null)
  const [filterValues, setFilterValues] = useState(filters[filterType])

  const resetAllFilters = () => {
    Object.keys(filterValues).forEach(key => {
      filterValues[key].value = []
    })
    setFilterValues(filterValues)
    filters[filterType] = filterValues
    dispatch(setFilters(filters))
    handleClose()
  }

  const setFilterValue = (values, object) => {
    filterValues[object.name].value = values
    setFilterValues(filterValues)
  }

  const handleClick = (event) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }
  const applyFilters = () => {
    filters[filterType] = filterValues
    dispatch(setFilters(filters))
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const filterIndicator = () => {
    let countSelected = 0
    Object.keys(filterValues).forEach(key => {
      if (filterValues[key].value.length) {
        countSelected += 1
      }
    })

    if (countSelected) {
      return <div className={classes.indicator} />
    }
    return <></>
  }

  return (
    <Box display='flex'>
        {
            exportFunc
              ? <IconButton onClick={ exportFunc }>
                    <GetAppRoundedIcon />
                </IconButton>
              : null
        }
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          style={{ width: filterType === 'input' ? null : 48 }}
          className={ open ? classes.buttonFilters : null }
        >
          {filterIndicator()}
          <img src='./Filter.svg' alt='filter_icon'/>
        </IconButton>
        <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
        >
            <div className={classes.modalFilters}>
                <Typography variant="h5" component="h5" className={ classes.title }>Filters</Typography>
                <div>
                    {
                        Object.keys(filterValues).map(key => (
                            <FormControl key={key} className={classes.oneFilter}>
                                <Select
                                  name={key}
                                  id={`filter-${key}`}
                                  placeholder={filterValues[key].label}
                                  isMulti
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={filterValues[key].values.map(item => ({
                                    value: item,
                                    label: item
                                  }))}
                                  onChange={setFilterValue}
                                  defaultValue={filterValues[key].value}
                                  menuPortalTarget={document.body}
                                  menuPosition={'fixed'}
                                  styles={{
                                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                  }}
                                />
                            </FormControl>
                        ))
                    }
                </div>
                <Box className={classes.controllButtons} display='flex' alignItems='center' justifyContent='space-between'>
                    <CustomButton action={resetAllFilters} title='Reset' />
                    <div>
                        <CustomButton action={handleClose} title='Cancel' />
                        <CustomButton action={applyFilters} title='Apply' colored style={{ marginLeft: 24 }} />
                    </div>
                </Box>
            </div>
        </Popover>
    </Box>
  )
}

FilterAndExport.propTypes = {
  exportFunc: PropTypes.func,
  filterType: PropTypes.oneOf(['input', 'output'])
}

export default React.memo(FilterAndExport, (props, nextProps) => {
  if (JSON.stringify(props) === JSON.stringify(nextProps)) {
    return true
  }
})
