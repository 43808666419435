import { SET_MAPS } from '../actions/MapActions'

const initialState = {
  maps: {}
}

export default function MapReducer (state = initialState, action) {
  switch (action.type) {
    case SET_MAPS:
      return {
        ...state,
        maps: action.value
      }
    default:
      return state
  }
}
