import React, { useEffect, useState } from 'react'
import SwitchType from '../components/SwitchType/SwitchType'
import BaselineSummary from '../components/BaselineSummary/BaselineSummary'
import BaselineData from '../components/BaselineData/BaselineData'
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/Header/Header'
import TopBlock from '../components/Blocks/TopBlock'
import { useHistory } from 'react-router-dom'
import Timer from '../components/Timer/Timer'
import CustomButton from '../components/Buttons/CustomButton'
import Data from '../services/Data'
import FiltersAndExport from '../components/FiltersAndExport/FiltersAndExport'
import { SET_TYPE, setState as setJobState } from '../redux/actions/JobActions'

const Input = () => {
  const inputData = useSelector(state => state.InputReducer)
  const jobData = useSelector(state => state.JobReducer)
  const dataSet = Data()
  const dispatch = useDispatch()
  const history = useHistory()
  const [timerIsStarted, setTimerIsStarted] = useState(false)

  const scenario = 'Baseline'

  const {
    viewType
  } = inputData

  const { jobStatus, jobKey } = jobData
  const jobType = jobData.type

  useEffect(() => {
    if (jobKey) {
      dataSet.stopTimer()
      dataSet.startTimer()
      setTimerIsStarted(true)
    }
  }, [jobKey])

  useEffect(() => {
    if (jobStatus && jobKey && jobType === 1) {
      const action = () => {
        dataSet.setOutputData().then(() => {
          dataSet.setNavigationStateGoNext(1, () => {
            setTimeout(() => {
              history.push('/output')
            }, 1000)
          })
        })
      }
      dataSet.checkJob(action)
    }
  }, [jobStatus, jobKey])

  const runOptimization = () => {
    dataSet.resetNavigation()
    dataSet.resetOutput()
    dataSet.resetScenario()
    dispatch(setJobState(SET_TYPE, 1))
    const action = () => {
      dataSet.startJob(() => {
        return dataSet.updateInputFiles()
      })
    }
    dataSet.updateScenarioConfig(scenario, action)
  }

  useEffect(() => {
    dataSet.setInputData()
    dataSet.setScenarios()
  }, [])

  useEffect(() => {
    dataSet.Input.setFilters()
  }, [inputData.input])

  const inputView = (
    <React.Fragment>
      <Header>
        {
          timerIsStarted
            ? <Timer stopHandler={() => { setTimerIsStarted(false) }} />
            : <CustomButton action={runOptimization} title='Run Baseline' colored />
        }
      </Header>
      <TopBlock title='Initial Input'>
        <div style={{ display: 'flex' }}>
          {
            viewType === 'summary'
              ? <FiltersAndExport filterType='input' />
              : null
          }
          <SwitchType />
        </div>
      </TopBlock>
        {
          viewType === 'summary'
            ? <BaselineSummary />
            : <BaselineData />
        }
    </React.Fragment>
  )

  if (!inputData.input.length) {
    return []
  }

  return inputView
}

export default Input
