import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import LineChart from '../LineChart/LineChart'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  card: {
    width: '100%',
    backgroundColor: theme.palette.secondaryBackground.cards,
    margin: theme.spacing(2, 0)
  },
  cardHeader: {
    padding: theme.spacing(0),
    margin: theme.spacing(3, 3, 0, 3)
  },
  cardContent: {
    paddingTop: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}))

function Mode ({ title, data, config, containerId }) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
       <CardHeader
        title={title}
        className={classes.cardHeader}
        disableTypography
        component='h3'
      />
        <CardContent className={classes.cardContent}>
        <div>
          <LineChart config={config} data={data} containerId={containerId} />
        </div>
        </CardContent>
    </Card>
  )
}

Mode.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  config: PropTypes.object,
  containerId: PropTypes.string
}

export default React.memo(Mode, (props, nextProps) => {
  if (props.data === nextProps.data &&
      props.title === nextProps.title &&
      props.containerId === nextProps.containerId) {
    return true
  }
})
