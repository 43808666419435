import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: theme.palette.secondaryBackground.cards
  },
  list: {
    paddingBottom: theme.spacing(0)
  },
  listItem: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  content: {
    padding: theme.spacing(3)
  },
  typographyName: {
    width: '70%',
    textAlign: 'start'
  },
  typographyContent: {
    marginLeft: theme.spacing(3),
    width: '30%',
    textAlign: 'center'
  }
}))

function ViewMetrics ({ metrics }) {
  const classes = useStyles()

  const listContent = metrics.map((oneMetric, key) => {
    return (
          <ListItem key={key} className={classes.listItem}>
            <Card className={classes.card}>
              <CardContent className={classes.content}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography
                    className={classes.typographyName}
                    variant="body1"
                    component="span"
                  >
                    {oneMetric.name}
                  </Typography>
                  <Typography
                    label={oneMetric.value}
                    className={classes.typographyContent}
                    variant="body2"
                    component="span"
                  >
                    {oneMetric.value}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </ListItem>
    )
  })

  return (
    <List component="nav" className={classes.list}>
      {listContent}
    </List>
  )
}

ViewMetrics.propTypes = {
  metrics: PropTypes.array
}

export default ViewMetrics
