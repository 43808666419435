const getBalloonColor = type => {
  let color
  switch (type) {
    case 'DC': {
      color = 'orange'
      break
    }
    case 'ODC': {
      color = 'blue'
      break
    }
    case 'SUP': {
      color = 'green'
      break
    }
    case 'ASUP': {
      color = 'purple'
      break
    }
    default: {
      color = 'black'
    }
  }

  return color
}

const clearValue = value => {
  return value.replace(/(\r\n|\n|\r)/gm, '').trim()
}

const radians = degrees => {
  return degrees * Math.PI / 180
}

const getDistance = (pointFrom, pointTo, miles = true) => {
  const R = miles ? 3961 : 6373

  const latFrom = radians(pointFrom[0])
  const lonFrom = radians(pointFrom[1])

  const latTo = radians(pointTo[0])
  const lonTo = radians(pointTo[1])

  const dlat = latTo - latFrom
  const dlon = lonTo - lonFrom

  const a = Math.sin(dlat / 2) ** 2 + Math.cos(latFrom) * Math.cos(latTo) * Math.sin(dlon / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c

  return 1.3 * distance
}

const getProgramConstant = (programVehicleConstantsTable, program, scenario = 'Baseline') => {
  let constantValue = 0
  if (typeof programVehicleConstantsTable[0] !== 'undefined') {
    programVehicleConstantsTable[0].file.data.forEach(item => {
      if (clearValue(item[0]) === program && clearValue(item[2]) === scenario) {
        constantValue = clearValue(item[1])
      }
    })
  }
  return constantValue
}

const trackCalculationSizeByMode = mode => {
  let size
  const constant = 3816

  switch (mode) {
    case 'TL': {
      size = constant * 0.92
      break
    }
    case 'MK': {
      size = constant * 0.83
      break
    }
    case 'ODC': {
      size = constant
      break
    }
    case 'LH': {
      size = constant * 0.8
      break
    }
    default: {
      size = constant
    }
  }

  return size
}

const getFacilitiesHash = ({ facilitiesTable, customersTable, suppliersTable }) => {
  const facilitiesDataHash = {}

  if (typeof facilitiesTable[0] !== 'undefined') {
    facilitiesTable[0].file.data.forEach(facility => {
      const facilityCodeParts = facility[1].split('_')

      facilitiesDataHash[facility[1]] = {
        latitude: parseFloat(facility[8]),
        longitude: parseFloat(facility[9]),
        name: facility[1],
        value: 14,
        color: getBalloonColor(facilityCodeParts[0]),
        country: clearValue(facility[6]),
        distance: getDistance([parseFloat(facility[8]), parseFloat(facility[9])], DCHAMTRAMCK),
        parts: 0
      }
    })
  }

  if (typeof customersTable[0] !== 'undefined') {
    customersTable[0].file.data.forEach(customer => {
      facilitiesDataHash[customer[1]] = {
        latitude: parseFloat(customer[7]),
        longitude: parseFloat(customer[8]),
        name: customer[1],
        value: 14,
        color: getBalloonColor(''),
        country: clearValue(customer[5]) ? clearValue(customer[5]) : 'US', // ???,
        distance: getDistance([parseFloat(customer[7]), parseFloat(customer[8])], DCHAMTRAMCK),
        parts: 0
      }
    })
  }

  if (typeof suppliersTable[0] !== 'undefined') {
    suppliersTable[0].file.data.forEach(supplier => {
      facilitiesDataHash[supplier[1]] = {
        latitude: parseFloat(supplier[7]),
        longitude: parseFloat(supplier[8]),
        name: supplier[1],
        value: 14,
        color: getBalloonColor(''),
        country: clearValue(supplier[6]),
        distance: getDistance([parseFloat(supplier[7]), parseFloat(supplier[8])], DCHAMTRAMCK),
        parts: 0
      }
    })
  }

  return facilitiesDataHash
}

const checkFilters = (filters, item) => {
  const checked = {}

  const checkedValues = {}
  Object.keys(filters).forEach(key => {
    if (Object.keys(item).indexOf(key) !== -1) {
      checkedValues[key] = filters[key].value.map(item => item.value)
    }
  })

  if (typeof checkedValues.modes !== 'undefined' && checkedValues.modes.indexOf('ODC') !== -1) {
    checkedValues.modes.push('LH')
  }

  Object.keys(item).forEach(key => {
    if (!checkedValues[key].length || checkedValues[key].indexOf(item[key]) !== -1) {
      checked[key] = true
    }
  })

  return Object.keys(checked).length === Object.keys(checkedValues).length
}

const checkAlternativeSupplier = (filtersData, item) => {
  return !(!filtersData.showAlternativeSuppliers && item.match(/ASUP_/))
}

const clearFloat = value => {
  let clearedValue = 0
  if (typeof value !== 'undefined') {
    clearedValue = decimalNumber(Number(value))
  }
  return clearedValue
}

const decimalNumber = number => {
  return Math.ceil(Number(number)) === Number(number) ? number : number.toFixed(2)
}

const MODES = ['TL', 'MK', 'ODC']
const MODES_FOR_METRICS = ['TL', 'MK', 'ODC', 'LH']
const DCHAMTRAMCK = [42.39269943, -83.05211095]

export {
  getBalloonColor,
  DCHAMTRAMCK,
  getDistance,
  getProgramConstant,
  clearValue,
  clearFloat,
  trackCalculationSizeByMode,
  getFacilitiesHash,
  checkFilters,
  checkAlternativeSupplier,
  decimalNumber,
  MODES,
  MODES_FOR_METRICS
}
