import { combineReducers } from 'redux'
import InputReducer from './InputReducer'
import OutputReducer from './OutputReducer'
import ScenarioReducer from './ScenarioReducer'
import AuthReducer from './AuthReducer'
import JobReducer from './JobReducer'
import FilterReducer from './FilterReducer'
import TimerReducer from './TimerReducer'
import MapReducer from './MapReducer'
import NavigationReducer from './NavigationReducer'

const rootReducer = combineReducers({
  InputReducer,
  OutputReducer,
  ScenarioReducer,
  AuthReducer,
  JobReducer,
  FilterReducer,
  TimerReducer,
  MapReducer,
  NavigationReducer
})

export default rootReducer
