import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import CustomButton from '../Buttons/CustomButton'
import Divider from '@material-ui/core/Divider'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import ModalAddScenario from './ModalAddScenario'
import Select, { components } from 'react-select'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    outline: 'none',
    width: theme.components.modals.width,
    height: theme.components.modals.height,
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0)
  },
  cardContent: {
    padding: theme.spacing(3),
    flex: 2
  },
  cardActions: {
    padding: theme.spacing(0, 3, 3, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 0
  },
  addScenario: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  addScenarioIcon: {
    display: 'block',
    margin: theme.spacing(0, 3)
  },
  buttonHovered: {
    backgroundColor: 'rgb(222,235,254)'
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight
  },
  header: {
    color: '#ffffff',
    flex: 1
  }
}))

function ModalChooseScenario ({ runFunction, scenarios, open, closeFunction, title }) {
  const classes = useStyles()

  const [selectedScenario, setSelectedScenario] = useState('')
  const handleCancel = () => {
    setSelectedScenario('')
    closeFunction()
  }
  const runOptimization = (scenario = '') => {
    closeFunction()
    runFunction(typeof scenario === 'string' && scenario ? scenario : selectedScenario)
  }

  const [openAddScenario, setOpenAddScenario] = useState(false)
  const [IsAddButtonHovered, setAddButtonHover] = useState(false)
  const handleOpen = () => { setOpenAddScenario(true) }
  const handleClose = () => { setOpenAddScenario(false) }
  const handleChooseSelect = (variant) => { setSelectedScenario(variant.value) }

  const ScenarioButton = (innerRef, innerProps) => {
    return (
      <div
        className={IsAddButtonHovered ? classes.buttonHovered : ''}
        onMouseEnter={() => setAddButtonHover(true)}
        onMouseLeave={() => setAddButtonHover(false)}
      >
        <Divider style={{ width: '95%', float: 'right' }}/>
        <div
          value=""
          ref={innerRef}
          {...innerProps}
          onClick={handleOpen}
          className={classes.addScenario}

        >
          <AddRoundedIcon className={classes.addScenarioIcon}/>
          <div>Add Scenario</div>
        </div>
      </div>
    )
  }

  const CustomOption = props => {
    const { data, innerRef, innerProps } = props
    return data.custom
      ? (ScenarioButton(innerRef, innerProps))
      : (<components.Option {...props} />)
  }

  CustomOption.propTypes = {
    data: PropTypes.object,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object
  }

  const splitScenarios = () => {
    const options = []
    scenarios.forEach(scenario => { options.push({ value: scenario, label: scenario }) })
    options.push({ custom: true })
    return options
  }

  return (
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ outline: 0 }}
        className={classes.modal}
      >
        <Card className={classes.card}>
          <CardHeader
            action={
              <IconButton
                onClick={closeFunction}
                aria-label="settings"
                className={ classes.header }
              >
                <Close/>
              </IconButton>
            }
            title={title}
            color='primary'
            titleTypographyProps={{
              className: classes.title
            }}
            component='h4'
            className={classes.cardHeader}
          />
          <CardContent className={classes.cardContent}>
            <Select
              options={splitScenarios()}
              components={{ Option: CustomOption }}
              onChange={handleChooseSelect}
              value={selectedScenario ? { value: selectedScenario, label: selectedScenario } : null}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            />

            <ModalAddScenario
              openAddScenario={openAddScenario}
              onCreate={value => {
                setSelectedScenario(value)
              }}
              runScenario={value => {
                runOptimization(value)
              }}
              closeFunc={handleClose}
            />
          </CardContent>

          <CardActions className={classes.cardActions}>
              <CustomButton action={handleCancel} title='Cancel'/>
              <div>
                <CustomButton action={runOptimization} title='Apply & Run' colored disabled={!selectedScenario} />
              </div>
          </CardActions>
        </Card>
      </Modal>
  )
}

ModalChooseScenario.propTypes = {
  runFunction: PropTypes.func,
  scenarios: PropTypes.array,
  open: PropTypes.bool,
  closeFunction: PropTypes.func,
  title: PropTypes.string
}

export default ModalChooseScenario
