import { React } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
    padding: '10px 20px',
    '&:disabled': {
      borderColor: theme.palette.secondaryBackground.buttonBorder
    }
  }
}))

function CustomButton ({ action, children, title, colored, disabled, width, ...rest }) {
  const classes = useStyles()

  return (
      <Button
        disabled={disabled}
        variant={ colored ? 'contained' : 'outlined'}
        color={colored ? 'secondary' : 'default'}
        style={{ width: width || 'fit-content' }}
        onClick={action}
        startIcon={children}
        {...rest}
        className={classes.root}
        size='small'
      >
        {title}
      </Button>
  )
}

CustomButton.propTypes = {
  action: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
  colored: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string
}

export default CustomButton
