import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  oneTab: {
    minWidth: theme.components.metrics.oneTabWidth
  },
  panel: {
    marginTop: theme.spacing(3)
  }

}))

function a11yProps (index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  }
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {children}
      </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function ViewOutputMetrics ({ children }) {
  const classes = useStyles()

  const [value, setValue] = useState('one')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <div>
        <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
        >
          <Tab
            value="one"
            label={children[0].tabName}
            {...a11yProps('one')}
            className={classes.oneTab}
          />
          <Tab
            value="two"
            label={children[1].tabName}
            {...a11yProps('two')}
            className={classes.oneTab}
          />
          <Tab
            value="three"
            label={children[2].tabName}
            {...a11yProps('three')}
            className={classes.oneTab}
          />
        </Tabs>

      </div>

      <TabPanel value={value} index="one" className={classes.panel}>
        {children[0].tabBody}
      </TabPanel>

      <TabPanel value={value} index="two" className={classes.panel}>
        {children[1].tabBody}
      </TabPanel>

      <TabPanel value={value} index="three" className={classes.panel}>
        {children[2].tabBody}
      </TabPanel>
    </div>
  )
}

ViewOutputMetrics.propTypes = {
  children: PropTypes.array
}

export default ViewOutputMetrics
