const SET_TIMER = 'SET_TIMER'
const SET_JOB_TIMER = 'SET_JOB_TIMER'

const setTimer = value => {
  return { type: SET_TIMER, value }
}

const setJobTimer = value => {
  return { type: SET_JOB_TIMER, value }
}

export {
  setTimer,
  setJobTimer,
  SET_TIMER,
  SET_JOB_TIMER
}
