import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import EditableRow from './EditableRow'
import IconButton from '@material-ui/core/IconButton'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    boxShadow: '0px 2px 8px -4px rgba(0,0,0,0.15)'
  }

}))

function CustomTableBody ({ rowsData, order, orderBy, page, rowsPerPage, emptyRows, handleEditCell, addRow, duplicateRow, deleteRow }) {
  const classes = useStyles()
  const [updatedRowsData, setUpdatedRowsData] = useState(rowsData)
  if (JSON.stringify(rowsData) !== JSON.stringify(updatedRowsData)) { setUpdatedRowsData(rowsData) }

  function getComparator (order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort (array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function descendingComparator (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [currentRow, setCurrentRow] = useState(null)
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget)
    setCurrentRow(index)
  }
  const handleClose = () => { setAnchorEl(null) }
  const open = Boolean(anchorEl)
  const id = open ? 'menu-popover' : undefined

  return (
    <TableBody>
        {stableSort(updatedRowsData, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const array = Object.values(row)
            const names = Object.keys(row)
            const result = []
            for (let i = 0; i < array.length; i++) {
              result.push(<EditableRow key={i} value={array[i]} onApply={handleEditCell} cellIndex={i} rowIndex={index} columnName={names[i]}/>)
            }

            result.push(
              <TableCell key='icon' padding="checkbox" id={`first-${index}`}>
                <IconButton
                  aria-describedby={id}
                  onClick={(event) => handleClick(event, index)}
                >
                  <MoreVertRoundedIcon/>
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  classes={{
                    paper: classes.popoverPaper
                  }}
                >
                   <Card>
                    <CardContent style={{ padding: 12 }}>
                      <List component="ul" aria-label="main mailbox folders">

                        <ListItem button style={{ padding: 0 }} onClick={() => addRow(currentRow)}>
                        <ListItemText primary="Add Row" style={{ marginRight: 12 }}/>
                          <ListItemIcon style={{ minWidth: 0 }}>
                            <AddRoundedIcon />
                          </ListItemIcon>
                        </ListItem>

                        <ListItem button style={{ padding: 0 }} onClick={() => duplicateRow(currentRow)}>
                          <ListItemText primary="Duplicate Row" style={{ marginRight: 12 }} />
                          <ListItemIcon style={{ minWidth: 0 }}>
                            <FileCopyOutlinedIcon />
                          </ListItemIcon>
                        </ListItem>

                        <ListItem button style={{ padding: 0 }} onClick={() => deleteRow(currentRow)}>
                          <ListItemText primary="Delete Row" style={{ marginRight: 12 }}/>
                          <ListItemIcon style={{ minWidth: 0 }}>
                            <CloseRoundedIcon />
                          </ListItemIcon>
                        </ListItem>

                        </List>
                    </CardContent>
                  </Card>

                </Popover>
              </TableCell>
            )
            return (
            <TableRow key={index}>
                {result}
            </TableRow>
            )
          })}
        {emptyRows > 0 && (
        <TableRow>
            <TableCell colSpan={6} />
        </TableRow>
        )}
    </TableBody>
  )
}

CustomTableBody.propTypes = {
  rowsData: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  handleEditCell: PropTypes.func,
  addRow: PropTypes.func,
  duplicateRow: PropTypes.func,
  deleteRow: PropTypes.func
}

export default CustomTableBody
