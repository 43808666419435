import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

function ArcSwitcher ({ checkedShow, changeShowArcs, checkedAlternative, changeAlternative }) {
  return (
        <Box>
           <Box display='flex' alignItems='center'>
            <Box>
              <Typography variant="body1" component="span">Show arcs</Typography>
              <Switch
                checked={checkedShow}
                onChange={changeShowArcs}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            <Box>
              <Typography variant="body1" component="span">Show alternative suppliers</Typography>
              <Switch
                checked={checkedAlternative}
                onChange={changeAlternative}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
          </Box>
        </Box>
  )
}

ArcSwitcher.propTypes = {
  checkedShow: PropTypes.bool,
  changeShowArcs: PropTypes.func,
  checkedAlternative: PropTypes.bool,
  changeAlternative: PropTypes.func
}

export default ArcSwitcher
