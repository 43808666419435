const SET_INPUT_VIEW_TYPE = 'SET_INPUT_VIEW_TYPE'
const SET_INPUT_DATA = 'SET_INPUT_DATA'
const SET_METRICS = 'SET_METRICS'

const setState = (type, value) => {
  return { type, value }
}

export {
  setState,
  SET_INPUT_VIEW_TYPE,
  SET_INPUT_DATA,
  SET_METRICS
}
