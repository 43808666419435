import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.components.titleBlock.height,
    padding: theme.spacing(3)
  }
}))

function TopBlock ({ title, children }) {
  const classes = useStyles()

  return (
    <Box className={classes.root} display='flex' alignItems='center' justifyContent='space-between'>
      <Typography variant="h1" component="h1">
        { title }
      </Typography>
      {children}
    </Box>
  )
}

TopBlock.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string
}

export default TopBlock
