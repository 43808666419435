import React, { useEffect, useState } from 'react'
import Chart from '../Chart/Chart'
import Map from '../Map/Map'
import { useSelector, useDispatch } from 'react-redux'
import Data from '../../services/Data'
import ViewMetrics from './ViewMetrics'
import CombineMetrics from './CombineMetrics'
import CombineGraphs from './CombineGraphs'
import { setAlternativeSuppliers } from '../../redux/actions/FiltersActions'
import ArcSwitcher from '../ArcSwitcher/ArcSwitcher'
import MapLegend from '../Map/MapLegend'
import Box from '@material-ui/core/Box'

const BaselineSummary = () => {
  const [showArcs, setShowArcs] = useState(false)
  const dataSet = Data()

  const filtersData = useSelector(state => state.FilterReducer)
  const filters = filtersData.filters.input

  const dispatch = useDispatch()

  useEffect(() => {
    dataSet.Input.setMetrics()
  }, [JSON.stringify(filters)])

  useEffect(() => {
    dataSet.Input.setMetrics()
  }, [filtersData.showAlternativeSuppliers])

  const changeShowArcs = () => {
    setShowArcs(!showArcs)
  }

  const {
    coordinates,
    lines
  } = dataSet.Input.map(showArcs)

  return (
    <Box display='flex'>
      <CombineMetrics title='Metrics'>
        <ViewMetrics metrics={dataSet.Input.metrics()} />
      </CombineMetrics>
      <CombineGraphs
        arcSwitcher={
          <ArcSwitcher
            checkedShow={showArcs}
            changeShowArcs={changeShowArcs}
            checkedAlternative={filtersData.showAlternativeSuppliers}
            changeAlternative={() => { dispatch(setAlternativeSuppliers((!filtersData.showAlternativeSuppliers))) }}
          />
        }
        map={
          <div>
            <MapLegend/>
            <Map showArcs={showArcs} coordinates={coordinates} lines={lines} containerId="chartdiv" />
          </div>

        }
        leftChart={
          {
            title: 'Supplier Distance Histogram',
            content: <Chart key='1' titles={{ left: 'Suppliers', bottom: 'Distance band' }} data={dataSet.Input.suppliersWithBandOfDistance()} containerId="chartdiv2" />
          }
        }
        rightChart={
          {
            title: 'Parts per Supplier Histogram',
            content: <Chart key='1' titles={{ left: 'Suppliers', bottom: 'Parts quantity band' }} data={dataSet.Input.suppliersWithPartQuantityBand()} containerId="chartdiv3" />
          }
        }
      />
    </Box>
  )
}

export default BaselineSummary
