import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Navigation from './Navigation'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.components.header.backgroundColor,
    paddingRight: theme.spacing(3)
  }
}))

function Header ({ children }) {
  const classes = useStyles()

  return <AppBar position="static" className={classes.root}>
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
      <Navigation/>
      {children}
    </Box>
  </AppBar>
}

Header.propTypes = {
  children: PropTypes.element
}

export default Header
