import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { setFilters as dispatchFilters } from '../../redux/actions/FiltersActions'
import {
  checkFilters,
  checkAlternativeSupplier,
  clearFloat,
  clearValue,
  DCHAMTRAMCK,
  getDistance,
  getFacilitiesHash,
  getProgramConstant,
  MODES,
  MODES_FOR_METRICS,
  trackCalculationSizeByMode,
  decimalNumber
} from './ServiceDataHelpers'

const OutputDataService = props => {
  let inputData = useSelector(state => state.InputReducer)
  let outputData = useSelector(state => state.OutputReducer)
  if (typeof props !== 'undefined' && typeof props.scenario !== 'undefined' && props.scenario) {
    const scenarioData = useSelector(state => state.ScenarioReducer)
    inputData = scenarioData.scenario
    outputData = scenarioData.scenario
  }
  const filtersData = useSelector(state => state.FilterReducer)
  const flowsTable = outputData.output.filter(item => item.name === 'flows.csv')
  const networkSummaryTable = outputData.output.filter(item => item.name === 'network_summary.csv')
  const facilitiesTable = inputData.input.filter(item => item.name === 'facilities.csv')
  const customersTable = inputData.input.filter(item => item.name === 'customers.csv')
  const suppliersTable = inputData.input.filter(item => item.name === 'suppliers.csv')
  const programVehicleConstantsTable = inputData.input.filter(item => item.name === 'program_vehicle_constants.csv')

  const filters = filtersData.filters
  const dispatch = useDispatch()

  const setFilters = () => {
    const modesHash = []
    const productsHash = []

    if (typeof flowsTable[0] !== 'undefined') {
      flowsTable[0].file.data.forEach(item => {
        if (MODES.indexOf(item[6]) !== -1) {
          modesHash[item[6]] = item[6]
        }
        productsHash[item[5]] = item[5]
      })

      const products = []
      Object.keys(productsHash).forEach(productIndex => {
        products.push(productsHash[productIndex])
      })

      const modes = []
      Object.keys(modesHash).forEach(modIndex => {
        modes.push(modesHash[modIndex])
      })

      filters.output.modes.values = modes
      filters.output.products.values = products
    }

    if (typeof customersTable[0] !== 'undefined') {
      const programs = []

      customersTable[0].file.data.forEach(customer => {
        const customerNameParts = customer[1].split('_')
        programs.push(customerNameParts[1])
      })

      filters.output.programs.values = programs
    }

    if (typeof facilitiesTable[0] !== 'undefined' || typeof suppliersTable[0] !== 'undefined') {
      const suppliersHash = {}

      if (typeof facilitiesTable[0] !== 'undefined') {
        facilitiesTable[0].file.data.forEach(supplier => {
          if (supplier[1].match(/SUP_/)) {
            suppliersHash[supplier[1]] = supplier[1]
          }
        })
      }

      if (typeof suppliersTable[0] !== 'undefined') {
        suppliersTable[0].file.data.forEach(supplier => {
          if (supplier[1].match(/SUP_/)) {
            suppliersHash[supplier[1]] = supplier[1]
          }
        })
      }

      filters.output.suppliers.values = Object.keys(suppliersHash)
    }

    dispatch(dispatchFilters(filters))
  }

  const checkFlowFilters = flow => {
    const productParts = flow[5].split('_')
    const programs = typeof productParts[0] !== 'undefined' ? productParts[0] : ''

    return checkAlternativeSupplier(filtersData, flow[3]) &&
        checkFilters(filters.output, { modes: flow[6], products: flow[5], suppliers: flow[3], programs })
  }

  const facilitiesDataHash = getFacilitiesHash({ facilitiesTable, customersTable, suppliersTable })

  const getFlowsSuppliersHash = ({ flowsTable, facilitiesDataHash }) => {
    const suppliersHash = {}

    if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
      flowsTable[0].file.data.forEach(flow => {
        if (flow[3].match(/SUP_/) && checkFlowFilters(flow)) {
          const supplier = facilitiesDataHash[flow[3]]
          if (typeof supplier !== 'undefined') {
            suppliersHash[flow[3]] = supplier
          }
        }
      })
    }
    return suppliersHash
  }
  const flowsSuppliersDataHash = getFlowsSuppliersHash({ flowsTable, facilitiesDataHash })

  const outputMap = showArches => {
    const linesHash = {}
    const coordinatesHash = {}

    if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
      flowsTable[0].file.data.forEach(item => {
        if (typeof facilitiesDataHash[item[3]] !== 'undefined' && typeof facilitiesDataHash[item[4]] !== 'undefined') {
          const arc = []
          arc.push(facilitiesDataHash[item[3]])
          arc.push(facilitiesDataHash[item[4]])

          if (checkFlowFilters(item)) {
            linesHash[item[3] + item[4]] = arc

            facilitiesDataHash[item[3]].parts += 1
            facilitiesDataHash[item[4]].parts += 1

            coordinatesHash[item[3]] = facilitiesDataHash[item[3]]
            coordinatesHash[item[4]] = facilitiesDataHash[item[4]]
          }
        }
      })
    }

    const lines = []
    Object.keys(linesHash).forEach(lineIndex => {
      lines.push(linesHash[lineIndex])
    })

    const coordinates = []
    Object.keys(coordinatesHash).forEach(coordinateIndex => {
      coordinates.push(coordinatesHash[coordinateIndex])
    })

    return {
      coordinates: coordinates,
      lines: showArches ? lines : []
    }
  }

  const suppliersWithBandOfDistance = () => {
    const data = [{
      name: '0-100',
      value: 0
    }, {
      name: '100-500',
      value: 0
    }, {
      name: '500-1000',
      value: 0
    }, {
      name: '1000+',
      value: 0
    }]

    if (Object.keys(flowsSuppliersDataHash).length) {
      Object.keys(flowsSuppliersDataHash).forEach(supplierName => {
        const supplier = flowsSuppliersDataHash[supplierName]
        const distance = getDistance([supplier.latitude, supplier.longitude], DCHAMTRAMCK)
        if (distance >= 1000) {
          data[3].value += 1
        } else if (distance >= 500) {
          data[2].value += 1
        } else if (distance >= 100) {
          data[1].value += 1
        } else {
          data[0].value += 1
        }
      })
    }

    return data
  }

  const suppliersWithPartQuantityBand = () => {
    const data = [{
      name: '1',
      value: 0
    }, {
      name: '2-5',
      value: 0
    }, {
      name: '6-10',
      value: 0
    }, {
      name: '11-20',
      value: 0
    }, {
      name: '20+',
      value: 0
    }]

    if (typeof flowsTable[0] !== 'undefined') {
      const flowsHash = {}
      flowsTable[0].file.data.forEach(flow => {
        if (flow[3].match(/SUP_/) && checkFlowFilters(flow)) {
          if (typeof flowsHash[flow[3]] === 'undefined') {
            flowsHash[flow[3]] = 0
          }
          flowsHash[flow[3]] += 1
        }
      })

      Object.keys(flowsHash).forEach(key => {
        if (flowsHash[key] > 20) {
          data[4].value += 1
        } else if (flowsHash[key] > 11) {
          data[3].value += 1
        } else if (flowsHash[key] >= 6) {
          data[2].value += 1
        } else if (flowsHash[key] >= 2) {
          data[1].value += 1
        } else {
          data[0].value += 1
        }
      })
    }

    return data
  }

  const costs = (scenario = 'Baseline') => {
    const transportationCost = program => {
      let cost = 0
      let fullCost = 0

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(item => {
          const regexp = new RegExp(`${program}_`, 'g')
          if (item[5].match(regexp)) {
            if (checkFlowFilters(item)) {
              cost += parseFloat(item[9])
            }
            fullCost += parseFloat(item[9])
          }
        })
      }

      cost = decimalNumber(cost / getProgramConstant(programVehicleConstantsTable, program, scenario))
      fullCost = decimalNumber(fullCost / getProgramConstant(programVehicleConstantsTable, program, scenario))

      return {
        value: cost,
        percent: decimalNumber(cost / fullCost * 100)
      }
    }

    const milesPerUnit = program => {
      let miles = 0
      let fullMiles = 0

      if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
        flowsTable[0].file.data.forEach(flow => {
          const distance = parseFloat(clearValue(flow[8]))
          const regexp = new RegExp(`${program}_`, 'g')
          if (flow[5].match(regexp) && MODES_FOR_METRICS.indexOf(clearValue(flow[6])) !== -1) {
            if (checkFlowFilters(flow)) {
              miles += (parseFloat(clearValue(flow[7])) / trackCalculationSizeByMode(clearValue(flow[6]))) * distance
            }
            fullMiles += (parseFloat(clearValue(flow[7])) / trackCalculationSizeByMode(clearValue(flow[6]))) * distance
          }
        })
      }

      miles = decimalNumber(miles / getProgramConstant(programVehicleConstantsTable, program, scenario))
      fullMiles = decimalNumber(fullMiles / getProgramConstant(programVehicleConstantsTable, program, scenario))

      return {
        value: miles,
        percent: decimalNumber(miles / fullMiles * 100)
      }
    }

    const cubePerSupplier = () => {
      let cube = 0

      let fullCube = 0

      const uniqueSuppliersHash = {}
      const fullUniqueSuppliersHash = {}

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(flow => {
          if (flow[3].match(/SUP_/)) {
            if (checkFlowFilters(flow)) {
              cube += parseFloat(clearValue(flow[7]))
              uniqueSuppliersHash[flow[3]] = flow[3]
            }
            fullCube += parseFloat(clearValue(flow[7]))
            fullUniqueSuppliersHash[flow[3]] = flow[3]
          }
        })
      }

      const countSuppliers = Object.keys(uniqueSuppliersHash).length
      const fullCountSuppliers = Object.keys(fullUniqueSuppliersHash).length

      const value = countSuppliers ? (cube / countSuppliers) : 0
      const fullValue = fullCountSuppliers ? (fullCube / fullCountSuppliers) : 0

      return {
        value: decimalNumber(value),
        percent: decimalNumber(value / fullValue * 100)
      }
    }

    const cubePerUnit = program => {
      let cube = 0
      let fullCube = 0

      const regexp = new RegExp(`${program}_`, 'g')

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(flow => {
          if (flow[3].match(/SUP_/) && flow[5].match(regexp)) {
            if (checkFlowFilters(flow)) {
              cube += parseFloat(clearValue(flow[7]))
            }
            fullCube += parseFloat(clearValue(flow[7]))
          }
        })
      }

      const value = (cube / getProgramConstant(programVehicleConstantsTable, program, scenario))
      const fullValue = (fullCube / getProgramConstant(programVehicleConstantsTable, program, scenario))

      return {
        value: decimalNumber(value),
        percent: decimalNumber(value / fullValue * 100)
      }
    }

    return [{
      name: 'Transportation Cost Per FG Unit',
      price: `$${transportationCost('FG').value}`,
      percent: transportationCost('FG').percent
    }, {
      name: 'Transportation Cost Per CC Unit',
      price: `$${transportationCost('CC').value}`,
      percent: transportationCost('CC').percent
    }, {
      name: 'Miles Per FG Unit',
      price: milesPerUnit('FG').value,
      percent: milesPerUnit('FG').percent
    }, {
      name: 'Miles Per CC Unit',
      price: milesPerUnit('CC').value,
      percent: milesPerUnit('CC').percent
    }, {
      name: 'Cube per Supplier',
      price: cubePerSupplier().value,
      percent: cubePerSupplier().percent
    }, {
      name: 'Cube Per FG Unit',
      price: cubePerUnit('FG').value,
      percent: cubePerUnit('FG').percent
    }, {
      name: 'Cube Per CC Unit',
      price: cubePerUnit('CC').value,
      percent: cubePerUnit('CC').percent
    }]
  }

  const risks = () => {
    const weightedAverageDistance = () => {
      let weightedAverageDistance = 0
      let sumFlowUnits = 0

      let fullWeightedAverageDistance = 0
      let fullSumFlowUnits = 0

      if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
        flowsTable[0].file.data.forEach(flow => {
          const distance = parseFloat(clearValue(flow[8]))
          if (MODES_FOR_METRICS.indexOf(flow[6]) !== -1) {
            if (checkFlowFilters(flow)) {
              sumFlowUnits += parseFloat(clearValue(flow[7]))
              weightedAverageDistance += parseFloat(clearValue(flow[7])) * distance
            }

            fullSumFlowUnits += parseFloat(clearValue(flow[7]))
            fullWeightedAverageDistance += parseFloat(clearValue(flow[7])) * distance
          }
        })
      }

      const value = (weightedAverageDistance / sumFlowUnits)
      const fullValue = (fullWeightedAverageDistance / fullSumFlowUnits)

      const calculatedValue = sumFlowUnits ? weightedAverageDistance / sumFlowUnits : 0
      const calculatedPercent = value && fullValue ? (value / fullValue * 100) : 0

      return {
        value: decimalNumber(calculatedValue),
        percent: decimalNumber(calculatedPercent)
      }
    }

    // const averageLeadTime = () => {
    //   let sumOfDistances = 0
    //   let countUnits = 0

    //   let sumOfFullDistances = 0
    //   let fullCountUnits = 0

    //   if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
    //     flowsTable[0].file.data.forEach(flow => {
    //       const distance = parseFloat(clearValue(flow[8]))
    //       if (MODES_FOR_METRICS.indexOf(flow[6]) !== -1) {
    //         if (checkFlowFilters(flow)) {
    //           countUnits += 1
    //           sumOfDistances += distance
    //         }
    //         fullCountUnits += 1
    //         sumOfFullDistances += distance
    //       }
    //     })
    //   }

    //   const value = countUnits ? (sumOfDistances / countUnits) : 0
    //   const fullValue = fullCountUnits ? (sumOfFullDistances / fullCountUnits) : 0

    //   return {
    //     value: decimalNumber(value),
    //     percent: decimalNumber(value / fullValue * 100)
    //   }
    // }

    const gmRating = () => {
      return {
        value: 4,
        percent: 100
      }
    }

    const longestLeadTimeSupplier = () => {
      const longestLeadTimeSupplier = {
        distance: 0,
        name: ''
      }

      let fullDistance = 0

      if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
        flowsTable[0].file.data.forEach(flow => {
          const distance = parseFloat(clearValue(flow[8]))
          if (checkFlowFilters(flow) && distance > longestLeadTimeSupplier.distance) {
            longestLeadTimeSupplier.distance = decimalNumber(distance)
            longestLeadTimeSupplier.name = clearValue(flow[3])
          }
          if (distance > fullDistance) {
            fullDistance = decimalNumber(distance)
          }
        })
      }

      const longestLeadTimeSupplierPercent = fullDistance ? (longestLeadTimeSupplier.distance / fullDistance * 100) : 0

      return {
        value: longestLeadTimeSupplier,
        percent: decimalNumber(longestLeadTimeSupplierPercent)
      }
    }

    const longestLeadTimePart = supplier => {
      const longestLeadTimePart = []
      const fullLongestLeadTimePart = []

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(flow => {
          if (clearValue(flow[3]) === supplier) {
            if (checkFlowFilters(flow)) {
              longestLeadTimePart.push(clearValue(flow[5]))
            }
            fullLongestLeadTimePart.push(clearValue(flow[5]))
          }
        })
      }

      const fullLongestLeadTimePartPercent = fullLongestLeadTimePart.length ? longestLeadTimePart.length / fullLongestLeadTimePart.length * 100 : 0

      return {
        value: longestLeadTimePart.length,
        percent: decimalNumber(fullLongestLeadTimePartPercent)
      }
    }

    const domesticSupply = () => {
      let isUSA = 0
      let isNotUSA = 0

      let fullUSA = 0
      let fullIsNotUSA = 0

      if (typeof flowsTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
        flowsTable[0].file.data.forEach(flow => {
          const facility = facilitiesDataHash[flow[3]]

          if (typeof facility !== 'undefined') {
            if (checkFlowFilters(flow)) {
              if (['US', 'United States'].indexOf(facility.country) !== -1) {
                isUSA += 1
              } else {
                isNotUSA += 1
              }
            }

            if (['US', 'United States'].indexOf(facility.country) !== -1) {
              fullUSA += 1
            } else {
              fullIsNotUSA += 1
            }
          }
        })
      }

      const value = (isNotUSA + isUSA) ? (isUSA / (isNotUSA + isUSA) * 100) : 0
      const fullValue = (fullUSA / (fullIsNotUSA + fullUSA) * 100)

      const percent = fullValue ? (value / fullValue * 100) : 0

      return {
        value: `${decimalNumber(value)}%`,
        percent: decimalNumber(percent)
      }
    }

    return [{
      name: 'Weighted Average Distance',
      price: weightedAverageDistance().value,
      percentage: `${weightedAverageDistance().percent}%`
    },
    // {
    //   name: 'Average Lead Time',
    //   price: averageLeadTime().value,
    //   percentage: `${averageLeadTime().percent}%`
    // },
    {
      name: 'GM Rating (for a scenario)',
      price: gmRating().value,
      percentage: `${gmRating().percent}%`
    },
    {
      name: 'Longest Lead Time Supplier',
      price: longestLeadTimeSupplier().value.distance,
      percentage: `${longestLeadTimeSupplier().percent}%`
    },
    {
      name: 'Longest Lead Time Part',
      price: longestLeadTimePart(longestLeadTimeSupplier().value.name).value,
      percentage: `${longestLeadTimePart(longestLeadTimeSupplier().value.name).percent}%`
    },
    {
      name: 'Domestic Supply',
      price: domesticSupply().value,
      percentage: `${domesticSupply().percent}%`
    }]
  }

  const costsChartData = () => {
    let data = { data: {}, config: {} }

    let transportationCost = 0

    if (typeof flowsTable[0] !== 'undefined') {
      flowsTable[0].file.data.forEach(item => {
        if (checkFlowFilters(item)) {
          transportationCost += parseFloat(clearValue(item[9]))
        }
      })
    }

    if (networkSummaryTable.length) {
      const config = {
        transportation: { label: 'Transportation', color: '#616FF6' },
        sourcing: { label: 'Sourcing', color: '#F5787B' },
        handling: { label: 'Handling', color: '#BABABA' },
        inventory: { label: 'Inventory', color: '#2D9AC4' },
        inbound: { label: 'Inbound', color: '#C9C9C9' },
        outbound: { label: 'Outbound', color: '#000000' },
        fixed: { label: 'Fixed', color: '#CDCDCD' },
        product: { label: 'Product', color: '#F5CF47' }
      }

      data = {
        data: [{
          name: 'Baseline',
          transportation: clearFloat(transportationCost),
          sourcing: clearFloat(networkSummaryTable[0].file.data[0][8]),
          handling: clearFloat(networkSummaryTable[0].file.data[0][12]),
          inventory: clearFloat(networkSummaryTable[0].file.data[0][7]),
          inbound: clearFloat(networkSummaryTable[0].file.data[0][9]),
          outbound: clearFloat(networkSummaryTable[0].file.data[0][10]),
          fixed: clearFloat(networkSummaryTable[0].file.data[0][11]),
          product: clearFloat(networkSummaryTable[0].file.data[0][6])
        }],
        config: config
      }
    }

    return data
  }

  const costsChartDataLegend = () => {
    const legend = []
    const config = costsChartData().config
    Object.keys(config).forEach(key => {
      const data = costsChartData().data[0][key]
      if (Math.ceil(data) > 0) {
        const item = {
          field: config[key].label,
          color: config[key].color
        }
        legend.push(item)
      }
    })
    return legend
  }

  const modesChartData = () => {
    const tcPercent = programs => {
      let sumByProgram = 0

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(item => {
          programs.forEach(program => {
            const regexp = new RegExp(program, 'g')
            if (item[6].match(regexp) && checkFlowFilters(item)) {
              sumByProgram += parseFloat(clearValue(item[9]))
            }
          })
        })
      }
      return decimalNumber(sumByProgram)
    }

    const cubePercent = programs => {
      let unitsByProgram = 0

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(item => {
          programs.forEach(program => {
            const regexp = new RegExp(program, 'g')
            if (item[6].match(regexp) && checkFlowFilters(item)) {
              unitsByProgram += parseFloat(clearValue(item[7]))
            }
          })
        })
      }
      return decimalNumber(unitsByProgram)
    }

    const suppliersPercent = programs => {
      const suppliersByProgram = {}

      if (typeof flowsTable[0] !== 'undefined') {
        flowsTable[0].file.data.forEach(item => {
          if (item[3].match(/SUP_/) && checkFlowFilters(item)) {
            programs.forEach(program => {
              const regexp = new RegExp(program, 'g')
              if (item[6].match(regexp)) {
                suppliersByProgram[item[3]] = item[3]
              }
            })
          }
        })
      }
      return decimalNumber(Object.keys(suppliersByProgram).length)
    }

    const config = {
      odc: { label: 'ODC', color: '#616FF6' },
      tl: { label: 'TL', color: '#F5787B' },
      mk: { label: 'MK', color: '#F5CF47' }
    }

    return [{
      title: 'Transportation Cost',
      data: [{ name: 'Baseline', tl: tcPercent(['TL']), mk: tcPercent(['MK']), odc: tcPercent(['ODC', 'LH']) }],
      config: config,
      containerId: 'linechart-1'
    }, {
      title: 'Cube',
      data: [{ name: 'Baseline', tl: cubePercent(['TL']), mk: cubePercent(['MK']), odc: cubePercent(['ODC']) }],
      config: config,
      containerId: 'linechart-2'
    }, {
      title: 'Number of Suppliers',
      data: [{
        name: 'Baseline',
        tl: suppliersPercent(['TL']),
        mk: suppliersPercent(['MK']),
        odc: suppliersPercent(['ODC', 'LH'])
      }],
      config: config,
      containerId: 'linechart-3'
    }]
  }

  const modesChartLegend = () => {
    return [{ field: 'ODC', color: '#616FF6' }, { field: 'TL', color: '#F5787B' }, { field: 'MK', color: '#F5CF47' }]
  }

  return {
    suppliersWithBandOfDistance,
    suppliersWithPartQuantityBand,
    map: showArches => {
      return outputMap(showArches)
    },
    setFilters,
    costs,
    risks,
    costsChartData,
    costsChartDataLegend,
    modesChartLegend,
    modesChartData
  }
}

OutputDataService.propTypes = {
  scenario: PropTypes.bool
}

export default OutputDataService
