import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.components.legend.mapLegendHeight
  },
  onePoint: {
    height: theme.components.legend.size,
    margin: theme.spacing(0, 2),
    fontFamily: theme.components.legend.fontFamily,
    fontSize: theme.components.legend.fontSize
  },
  coloredCircle: {
    width: theme.components.legend.size,
    height: '8px!important',
    borderRadius: theme.components.legend.borderRadius,
    marginRight: theme.components.legend.marginRight
  }
}))

function MapLegend () {
  const classes = useStyles()

  const legendData = [
    {
      name: 'DC',
      color: 'orange'
    },
    {
      name: 'ODC',
      color: 'blue'
    },
    {
      name: 'SUP',
      color: 'green'
    },
    {
      name: 'ASUP',
      color: 'purple'
    }
  ]

  const legendBody = legendData.map((element, key) => {
    return (
        <Box key={key} className={classes.onePoint} display='flex' alignItems='center'>
          <div className={classes.coloredCircle} style={{ backgroundColor: element.color }} />
          <span>{element.name}</span>
        </Box>
    )
  })

  return (
    <Box display='flex' alignItems='center' justifyContent='flex-end' className={classes.root}>
      {legendBody}
    </Box>
  )
}

export default MapLegend
