import { SET_INPUT_VIEW_TYPE, SET_INPUT_DATA, SET_METRICS } from '../actions/InputActions'

const initialState = {
  viewType: 'summary',
  input: [],
  metrics: [
    { name: 'Number of suppliers', value: 0 },
    { name: 'Number of ODCs', value: 0 },
    { name: 'Average distance between supplier and plant', value: 0 },
    { name: 'Supplier furthest from the plant', value: '' },
    { name: 'Number of part(s) supplied by furthest supplier', value: 0 }
  ]
}

export default function InputReducer (state = initialState, action) {
  switch (action.type) {
    case SET_INPUT_VIEW_TYPE:
      return {
        ...state,
        viewType: action.value
      }
    case SET_INPUT_DATA:
      return {
        ...state,
        input: action.value
      }
    case SET_METRICS:
      return {
        ...state,
        metrics: action.value
      }
    default:
      return state
  }
}
