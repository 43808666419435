import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  header: {
    padding: theme.spacing(0),
    margin: theme.spacing(3)
  },
  content: {
    paddingTop: theme.spacing(0)
  }

}))

function InventoryChart ({ title, children }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        className={classes.header}
        disableTypography
        component='h2'
      />
      <CardContent className={classes.content}>
        {children}
      </CardContent>
    </Card>
  )
}

InventoryChart.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object
}

export default InventoryChart
