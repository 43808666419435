import { saveAs } from 'file-saver'

const processRow = function (row) {
  let finalVal = ''
  for (let j = 0; j < row.length; j++) {
    const innerValue = row[j] === null ? '' : row[j].toString()
    let result = innerValue.replace(/"/g, '""')
    if (result.search(/("|,|\n)/g) >= 0) { result = '"' + result + '"' }
    if (j > 0) { finalVal += ',' }
    finalVal += result
  }
  return finalVal + '\n'
}

const exportCSV = (gettingData) => {
  const splitedGettingData = Object.entries(gettingData)
  const JSZip = require('jszip')
  const zip = new JSZip()

  splitedGettingData.forEach(oneDirectory => {
    const directoryName = oneDirectory[0]
    const directory = zip.folder(directoryName)
    oneDirectory[1].forEach(oneFile => {
      const fileName = oneFile.name
      const columns = oneFile.file.columns
      const data = oneFile.file.data
      const rows = [columns, ...data]
      let csvFile = ''
      for (let i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
      }
      directory.file(fileName, csvFile)
    })
  })

  zip.generateAsync({ type: 'blob' })
    .then(function (content) {
      saveAs(content, 'Otilogic.zip')
    })
}

export default exportCSV
