const SET_OUTPUT_DATA = 'SET_OUTPUT_DATA'
const RESET_OUTPUT_STATE = 'RESET_OUTPUT_STATE'

const setState = (type, value) => {
  return { type, value }
}

const resetOutputState = () => {
  return { type: RESET_OUTPUT_STATE }
}

export {
  setState,
  resetOutputState,
  SET_OUTPUT_DATA,
  RESET_OUTPUT_STATE
}
