import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: theme.palette.secondaryBackground.cards
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  margined: {
    marginRight: theme.spacing(2)
  },
  textAlignhed: {
    textAlign: 'end',
    lineHeight: theme.components.metrics.costLineHeight
  }
}))

function Risk ({ risks, isSetFilter }) {
  const classes = useStyles()

  const risksBody = risks.map((oneRisk, key) => {
    return (
          <ListItem key={key}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="body1" component="span">{oneRisk.name}</Typography>
                <div className={classes.textAlignhed}>
                  <Typography variant="body2" component="span" className={classes.margined}>{oneRisk.price}</Typography>
                  { isSetFilter ? <Typography variant="body1" component="span">{oneRisk.percentage}</Typography> : null}
                  {
                    typeof oneRisk.scenarioPrice !== 'undefined'
                      ? <React.Fragment>
                        <Divider/>
                        <Typography variant="body2" component="span" className={classes.margined}>{oneRisk.scenarioPrice}</Typography>
                        { isSetFilter ? <Typography variant="body1" component="span">{oneRisk.scenarioPercentage}</Typography> : null}
                        <span />
                      </React.Fragment>
                      : <></>
                  }
                </div>
              </CardContent>
            </Card>
          </ListItem>
    )
  })

  return (
    <List component="ul" aria-label="main mailbox folders">
      {risksBody}
    </List>
  )
}

Risk.propTypes = {
  risks: PropTypes.array,
  isSetFilter: PropTypes.bool
}

export default React.memo(Risk, (props, nextProps) => {
  if (props.risks === nextProps.risks) {
    return true
  }
})
