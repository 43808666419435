import { useDispatch, useSelector } from 'react-redux'
import { SET_INPUT_DATA, setState as setInputState } from '../redux/actions/InputActions'
import env from 'react-dotenv'
import API from './API'
import axios from 'axios'
import { SET_OUTPUT_DATA, setState as setOutputState, resetOutputState } from '../redux/actions/OutputActions'
import { setScenario, setScenarios, resetScenarioState } from '../redux/actions/ScenarioActions'
import InputDataService from './Data/InputDataService'
import OutputDataService from './Data/OutputDataService'
import ScenarioDataService from './Data/ScenarioDataService'
import { SET_CURRENT_JOB, SET_JOB_KEY, SET_STATUS, setState as setJobState } from '../redux/actions/JobActions'
import { setTimer, setJobTimer } from '../redux/actions/TimerActions'

const Data = () => {
  const inputData = useSelector(state => state.InputReducer)
  const authData = useSelector(state => state.AuthReducer)
  const scenarioData = useSelector(state => state.ScenarioReducer)
  const jobData = useSelector(state => state.JobReducer)
  const timerData = useSelector(state => state.TimerReducer)
  const navigation = useSelector(state => state.NavigationReducer)
  const dispatch = useDispatch()

  const { jobKey, scripts, currentJob } = jobData

  const apiKey = authData.apiKey
  const workspace = env.DEFAULT_WORKSPACE

  const prepareFileData = file => {
    const lines = file.split('\n')

    const data = { columns: [], data: [] }

    lines.forEach((line, index) => {
      if (index) {
        if (line) {
          data.data.push(line.split(','))
        }
      } else {
        line.split(',').forEach(item => {
          const name = item.replace(/(\r\n|\n|\r)/gm, '')
          data.columns.push(name)
        })
      }
    })

    return data
  }

  const setData = (filter, dispatcher = () => {}) => {
    const apiKey = authData.apiKey
    const workspace = env.DEFAULT_WORKSPACE

    return API.getWorkspaceFiles({ apiKey, workspace, filter }).then(async result => {
      const workspaces = result.data.files
      const queries = []
      workspaces.forEach(item => {
        const directory = item.directoryPath
        const filename = item.filename

        const query = API.getWorkspaceFile({ apiKey, workspace, directory, filename })
        queries.push(query)
      })

      const data = workspaces.map(item => { return { name: item.filename } })
      await axios.all(queries).then(files => {
        files.forEach((file, index) => {
          data[index].file = prepareFileData(file.data)
        })
        dispatcher(data)
      })
    })
  }

  const setScenarioFolders = (dispatcher = () => {}) => {
    const filter = env.SCENARIOS_PATH
    const apiKey = authData.apiKey
    const workspace = env.DEFAULT_WORKSPACE

    API.getWorkspaceFiles({ apiKey, workspace, filter }).then(result => {
      const allSubDirs = result.data.files.map(item => {
        const matches = item.directoryPath.match(/Scenarios\/(.*)\//)
        return matches && matches[1]
      })
      const directories = allSubDirs.filter((value, index, self) => {
        return value !== env.DEFAULT_SCENARIO && self.indexOf(value) === index
      })

      dispatcher(directories)
    })
  }

  const updateScenarioConfig = (scenario, action) => {
    const apiKey = authData.apiKey
    const workspace = env.DEFAULT_WORKSPACE
    const filename = env.SCENARIO_CONFIG_NAME
    const directory = env.PROJECT_DIRECTORY
    const scenariosDirectory = env.SCENARIOS_PATH

    const content = `/projects/${scenariosDirectory}/${scenario}`

    const file = new File([content], filename, {
      type: 'text/plain'
    })

    API.updateFile({ apiKey, workspace, directory, filename, file }).then(() => {
      action()
    })
  }

  const updateInputFiles = () => {
    return Promise.all(inputData.input.map(item => {
      const contentLines = []
      contentLines.push(item.file.columns.join(','))
      item.file.data.forEach(line => {
        contentLines.push(line.join(','))
      })
      const filename = item.name
      const file = contentLines.join('\n')

      const directory = env.INPUT_FILES_PATH

      return API.updateFile({ apiKey, workspace, directory, filename, file })
    }))
  }

  const updateInputData = (file, column, row, newValue) => {
    const input = inputData.input[file]
    const data = input.file.data
    data[row][column] = newValue
    inputData.input[file].file.data = data
    dispatch(setInputState(SET_INPUT_DATA, inputData.input))
  }

  const addEmptyRow = (file, nextRowNum) => {
    const input = inputData.input[file]
    const data = input.file.data
    const newRow = []
    for (let i = 0; i < data[0].length; i++) {
      newRow.push('')
    }
    data.splice(nextRowNum, 0, newRow)
    inputData.input[file].file.data = data
    dispatch(setInputState(SET_INPUT_DATA, inputData.input))
  }

  const duplicateRow = (file, nextRowNum) => {
    const input = inputData.input[file]
    const data = input.file.data
    const newRow = data[nextRowNum]
    data.splice(nextRowNum, 0, newRow)
    inputData.input[file].file.data = data
    dispatch(setInputState(SET_INPUT_DATA, inputData.input))
  }

  const deleteRow = (file, nextRowNum) => {
    const input = inputData.input[file]
    const data = input.file.data
    data.splice(nextRowNum, 1)
    inputData.input[file].file.data = data
    dispatch(setInputState(SET_INPUT_DATA, inputData.input))
  }

  const createJob = () => {
    const directory = env.PROJECT_DIRECTORY
    const filename = scripts[currentJob]

    API.createJob({ apiKey, workspace, directory, filename }).then(result => {
      dispatch(setJobState(SET_JOB_KEY, result.data.jobKey))
      dispatch(setJobState(SET_STATUS, 1))
    }).catch(() => {
      stopTimer()
      resetJobState()
    })
  }

  const startJob = beforeStart => {
    if (!jobKey) {
      if (!timerData.jobTimer) {
        startTimer()
      }

      if (typeof beforeStart !== 'undefined') {
        beforeStart().then(() => {
          createJob()
        })
      } else {
        createJob()
      }
    }
  }

  const stopJob = () => {
    if (jobKey) {
      try {
        stopTimer()
        resetJobState()
        return API.stopJob({ apiKey, workspace, jobKey })
      } catch (e) {}
    }

    return Promise.resolve()
  }

  const startTimer = () => {
    let time = 0
    dispatch(setTimer(time))

    const timer = setInterval(() => {
      time += 1
      dispatch(setTimer(time))
    }, 1000)

    dispatch(setJobTimer(timer))
  }

  const stopTimer = () => {
    clearInterval(timerData.jobTimer)
    dispatch(setJobTimer(null))
  }

  const checkJob = action => {
    const timer = setInterval(async () => {
      const jobStatusInfo = await API.getJob({ apiKey, workspace, jobKey })

      if (jobStatusInfo.data.status === 'cancelled') {
        clearInterval(timer)
      } else {
        const { status } = jobStatusInfo.data
        if (status === 'done') {
          clearInterval(timer)

          dispatch(setJobState(SET_JOB_KEY, ''))

          const theNextJob = currentJob + 1

          if (typeof scripts[theNextJob] !== 'undefined') {
            dispatch(setJobState(SET_CURRENT_JOB, theNextJob))
          } else {
            action()
          }
        }
      }
    }, 15000)
  }

  const resetJobState = () => {
    dispatch(setJobState(SET_JOB_KEY, ''))
    dispatch(setJobState(SET_STATUS, ''))
    dispatch(setJobState(SET_CURRENT_JOB, 0))
  }

  const resetTimer = () => {
    clearInterval(timerData.jobTimer)
    dispatch(setTimer(0))
  }

  const setNavigationStateGoNext = (page = 1, action) => {
    resetJobState()
    resetTimer()
    resetNavigation(page)
    action()
  }

  const resetOutput = () => {
    dispatch(resetOutputState())
  }

  const resetScenario = () => {
    dispatch(resetScenarioState())
  }

  const resetNavigation = (page = 0) => {
    const newValue = navigation.navigation
    newValue[1].validated = page >= 1
    newValue[2].validated = page === 2
    dispatch({ type: 'CHANGE', value: newValue })
  }

  const createScenario = scenarioName => {
    const filter = `${env.SCENARIOS_PATH}/${env.DEFAULT_SCENARIO}`
    return Promise.resolve(API.getWorkspaceFiles({ apiKey, workspace, filter }).then(result => {
      const queries = result.data.files.map(file => {
        const sourceDirectoryPath = file.directoryPath
        const sourceFilename = file.filename

        const targetDirectoryPath = sourceDirectoryPath.replace(`/${env.DEFAULT_SCENARIO}/`, `/${scenarioName}/`)
        const targetFilename = file.filename

        return API.copyFile({ apiKey, workspace, sourceDirectoryPath, sourceFilename, targetDirectoryPath, targetFilename })
      })

      return axios.all(queries)
    }))
  }

  return {
    addEmptyRow,
    updateInputData,
    updateInputFiles,
    duplicateRow,
    deleteRow,
    updateScenarioConfig,
    startJob,
    checkJob,
    stopJob,
    startTimer,
    stopTimer,
    setNavigationStateGoNext,
    resetOutput,
    resetScenario,
    resetNavigation,
    createScenario,
    setInputData: async () => {
      const filter = env.INPUT_FILES_PATH
      return await Promise.resolve(setData(filter, data => {
        dispatch(setInputState(SET_INPUT_DATA, data))
      }))
    },
    setOutputData: async () => {
      const filter = env.OUTPUT_FILES_PATH
      return await Promise.resolve(setData(filter, data => {
        dispatch(setOutputState(SET_OUTPUT_DATA, data))
      }))
    },
    setScenario: async () => {
      const configs = [
        { filter: env.INPUT_FILES_PATH, type: 'input' },
        { filter: env.OUTPUT_FILES_PATH, type: 'output' }
      ]

      const { scenario, current } = scenarioData

      return await Promise.all(configs.map(config => {
        const filter = config.filter.replace('/Baseline/', `/${current}/`)
        return setData(filter, data => {
          scenario[config.type] = data
          dispatch(setScenario(scenario))
        })
      }))
    },
    setScenarios: () => {
      setScenarioFolders(data => {
        dispatch(setScenarios(data))
      })
    },
    scenarios: scenarioData.scenarios,
    Input: InputDataService(),
    Output: OutputDataService(),
    Scenario: ScenarioDataService()
  }
}

export default Data
