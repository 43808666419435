import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    width: 'inherit',
    marginRight: theme.spacing(3)
  },
  content: {
    padding: theme.spacing(3)
  }
}))

function CombineGrid ({ children }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {children}
      </CardContent>
    </Card>
  )
}

CombineGrid.propTypes = {
  children: PropTypes.object
}

export default CombineGrid
