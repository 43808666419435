const SET_MAPS = 'SET_MAPS'

const setMaps = value => {
  return { type: SET_MAPS, value }
}

export {
  setMaps,
  SET_MAPS
}
