import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import PropTypes from 'prop-types'
import { theme } from '../../themeProvider'

function XyChart (props) {
  const {
    containerId,
    data
  } = props

  const drawXyChart = () => {
    if (!data.length) {
      return <></>
    }

    const chart = am4core.create(containerId, am4charts.XYChart)

    chart.data = data

    // // Create axes
    chart.xAxes.push(new am4charts.DateAxis())
    chart.yAxes.push(new am4charts.ValueAxis())

    createSeries('value0', 'Inventory', 'purple')
    createSeries('value1', 'Orders', 'green')
    createSeries('value2', 'Back Order Quantity', 'red')

    // Create series
    function createSeries (field, name, color) {
      const series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = field
      series.dataFields.dateX = 'date'
      series.name = name
      series.stroke = am4core.color(color)
      return series
    }

    chart.cursor = new am4charts.XYCursor()

    // Create a legend
    chart.legend = new am4charts.Legend()
    chart.legend.position = 'bottom'
    chart.legend.scrollable = true
  }

  useEffect(() => {
    drawXyChart()
  }, [containerId])

  return (
      <div className='chart-container' id={containerId} style={theme.components.inventoryChart} />
  )
}

XyChart.propTypes = {
  containerId: PropTypes.string,
  data: PropTypes.array
}

export default React.memo(XyChart, (props, nextProps) => {
  return JSON.stringify(props) === JSON.stringify(nextProps)
})
