import { SET_SCENARIO_DATA, SELECT_SCENARIO, SET_SCENARIOS, RESET_SCENARIO_STATE } from '../actions/ScenarioActions'

const initialState = {
  current: 'Baseline',
  scenarios: [],
  scenario: {
    input: [],
    output: []
  }
}

export default function OutputReducer (state = initialState, action) {
  switch (action.type) {
    case SET_SCENARIO_DATA:
      return {
        ...state,
        scenario: action.value
      }
    case SELECT_SCENARIO:
      return {
        ...state,
        current: action.value
      }
    case SET_SCENARIOS:
      return {
        ...state,
        scenarios: action.value
      }
    case RESET_SCENARIO_STATE: {
      const clearedState = initialState
      clearedState.scenarios = state.scenarios
      return {
        ...state,
        ...clearedState
      }
    }
    default:
      return state
  }
}
