import { useDispatch, useSelector } from 'react-redux'
import { SET_METRICS, setState as setInputState } from '../../redux/actions/InputActions'
import { checkFilters, checkAlternativeSupplier, DCHAMTRAMCK, getDistance, getFacilitiesHash, decimalNumber } from './ServiceDataHelpers'
import { setFilters as dispatchFilters } from '../../redux/actions/FiltersActions'

const InputDataService = () => {
  const inputData = useSelector(state => state.InputReducer)
  const filtersData = useSelector(state => state.FilterReducer)
  const dispatch = useDispatch()

  const facilitiesTable = inputData.input.filter(item => item.name === 'facilities.csv')
  const customersTable = inputData.input.filter(item => item.name === 'customers.csv')
  const suppliersTable = inputData.input.filter(item => item.name === 'suppliers.csv')
  const transportationLanesTable = inputData.input.filter(item => item.name === 'transportation_lanes.csv')
  const productionPoliciesTable = inputData.input.filter(item => item.name === 'production_policies.csv')

  const facilitiesDataHash = getFacilitiesHash({ facilitiesTable, customersTable, suppliersTable })

  const getProductionPoliciesHash = () => {
    const suppliersProductions = {}

    if (typeof productionPoliciesTable[0] !== 'undefined') {
      productionPoliciesTable[0].file.data.forEach(productionPolice => {
        if (checkProductPoliciesFilters(productionPolice)) {
          if (typeof suppliersProductions[productionPolice[1]] === 'undefined') {
            suppliersProductions[productionPolice[1]] = 0
          }
          if (checkProductPoliciesFilters(productionPolice)) {
            suppliersProductions[productionPolice[1]] += 1
          }
        }
      })
    }

    return suppliersProductions
  }

  const checkTLFilters = item => {
    const productParts = item[3].split('_')
    const programs = typeof productParts[0] !== 'undefined' ? productParts[0] : ''

    return checkAlternativeSupplier(filtersData, item[1]) &&
        checkFilters(filters.input, { products: item[3], suppliers: item[1], programs })
  }

  const checkFacilitiesFilters = item => {
    const suppliersProductions = getProductionPoliciesHash()
    let filtersChecked = false
    Object.keys(filters.input).forEach(key => {
      if (['programs', 'products'].indexOf(key) !== -1 && filters.input[key].value.length) {
        filtersChecked = true
      }
    })

    return (!filtersChecked || (filtersChecked && Object.keys(suppliersProductions).indexOf(item[1]) !== -1)) &&
      checkAlternativeSupplier(filtersData, item[1]) &&
          checkFilters(filters.input, { suppliers: item[1] })
  }

  const checkProductPoliciesFilters = item => {
    const productParts = item[2].split('_')
    const programs = typeof productParts[0] !== 'undefined' ? productParts[0] : ''

    return checkAlternativeSupplier(filtersData, item[1]) &&
        checkFilters(filters.input, { products: item[2], suppliers: item[1], programs })
  }

  const filters = filtersData.filters

  const inputMap = showArches => {
    const linesHash = {}
    const coordinatesHash = {}
    const suppliersProductions = getProductionPoliciesHash()

    if (typeof transportationLanesTable[0] !== 'undefined' && Object.keys(facilitiesDataHash).length) {
      transportationLanesTable[0].file.data.forEach(item => {
        if (checkTLFilters(item)) {
          const arc = []
          arc.push(facilitiesDataHash[item[1]])
          arc.push(facilitiesDataHash[item[2]])
          linesHash[item[1] + item[2]] = arc

          facilitiesDataHash[item[1]].parts = isNaN(suppliersProductions[item[1]]) ? 0 : suppliersProductions[item[1]]
          facilitiesDataHash[item[2]].parts += isNaN(suppliersProductions[item[2]]) ? 0 : suppliersProductions[item[1]]

          coordinatesHash[item[1]] = facilitiesDataHash[item[1]]
          coordinatesHash[item[2]] = facilitiesDataHash[item[2]]
        }
      })
    }

    const lines = []
    Object.keys(linesHash).forEach(lineIndex => {
      lines.push(linesHash[lineIndex])
    })

    const coordinates = []
    Object.keys(coordinatesHash).forEach(coordinateIndex => {
      coordinates.push(coordinatesHash[coordinateIndex])
    })

    return {
      coordinates: coordinates,
      lines: showArches ? lines : []
    }
  }

  const suppliersWithBandOfDistance = (scenario = false) => {
    const suppliersTable = inputData.input.filter(item => item.name === 'suppliers.csv')
    const facilitiesTable = inputData.input.filter(item => item.name === 'facilities.csv')

    const data = [{
      name: '0-100',
      value: 0
    }, {
      name: '100-500',
      value: 0
    }, {
      name: '500-1000',
      value: 0
    }, {
      name: '1000+',
      value: 0
    }]

    if (typeof suppliersTable[0] !== 'undefined') {
      suppliersTable[0].file.data.forEach(supplier => {
        if (checkFacilitiesFilters(supplier) && supplier[1].match(/SUP_/)) {
          const distance = getDistance([supplier[7], supplier[8]], DCHAMTRAMCK)
          if (distance >= 1000) {
            data[3].value += 1
          } else if (distance >= 500) {
            data[2].value += 1
          } else if (distance >= 100) {
            data[1].value += 1
          } else {
            data[0].value += 1
          }
        }
      })
    }

    if (typeof facilitiesTable[0] !== 'undefined') {
      facilitiesTable[0].file.data.forEach(facility => {
        if (checkFacilitiesFilters(facility) && facility[1].match(/SUP_/)) {
          const distance = getDistance([facility[8], facility[9]], DCHAMTRAMCK)
          if (distance >= 1000) {
            data[3].value += 1
          } else if (distance >= 500) {
            data[2].value += 1
          } else if (distance >= 100) {
            data[1].value += 1
          } else {
            data[0].value += 1
          }
        }
      })
    }

    if (scenario) {
      Object.keys(data).forEach(key => {
        data[key].value2 = data[key].value - 2
        if (data[key].value2 < 0) {
          data[key].value2 = 3
        }
      })
    }

    return data
  }

  const suppliersWithPartQuantityBand = () => {
    const data = [{
      name: '1',
      value: 0
    }, {
      name: '2-5',
      value: 0
    }, {
      name: '6-10',
      value: 0
    }, {
      name: '11-20',
      value: 0
    }, {
      name: '20+',
      value: 0
    }]

    if (typeof productionPoliciesTable[0] !== 'undefined') {
      const suppliersProductions = getProductionPoliciesHash()

      Object.keys(suppliersProductions).forEach(key => {
        if (suppliersProductions[key] > 20) {
          data[4].value += 1
        } else if (suppliersProductions[key] > 11) {
          data[3].value += 1
        } else if (suppliersProductions[key] >= 6) {
          data[2].value += 1
        } else if (suppliersProductions[key] >= 2) {
          data[1].value += 1
        } else {
          data[0].value += 1
        }
      })
    }

    return data
  }

  const setFilters = () => {
    if (typeof facilitiesTable[0] !== 'undefined' || typeof suppliersTable[0] !== 'undefined') {
      const suppliersHash = {}

      if (typeof facilitiesTable[0] !== 'undefined') {
        facilitiesTable[0].file.data.forEach(supplier => {
          if (supplier[1].match(/SUP_|ODC_/)) {
            suppliersHash[supplier[1]] = supplier[1]
          }
        })
      }

      if (typeof suppliersTable[0] !== 'undefined') {
        suppliersTable[0].file.data.forEach(supplier => {
          if (supplier[1].match(/SUP_|ODC_/)) {
            suppliersHash[supplier[1]] = supplier[1]
          }
        })
      }

      filters.input.suppliers.values = Object.keys(suppliersHash)
    }

    if (typeof transportationLanesTable[0] !== 'undefined') {
      const productsHash = {}
      const programsHash = {}

      transportationLanesTable[0].file.data.forEach(item => {
        productsHash[item[3]] = item[3]
        const customerNameParts = item[3].split('_')
        programsHash[customerNameParts[0]] = customerNameParts[0]
      })

      filters.input.programs.values = Object.keys(programsHash)
      filters.input.products.values = Object.keys(productsHash)
    }

    dispatch(dispatchFilters(filters))
  }

  return {
    baselineTableNames: () => {
      const tables = []

      inputData.input.forEach(item => {
        const { name } = item
        tables.push({
          name
        })
      })

      return tables
    },
    baselineTable: table => {
      const columns = inputData.input.length
        ? inputData.input[table].file.columns
        : []

      const data = inputData.input.length
        ? inputData.input[table].file.data
        : []
      return { columns, data }
    },
    metrics: () => {
      return inputData.metrics
    },
    setMetrics: () => {
      const facilitiesTable = inputData.input.filter(item => item.name === 'facilities.csv')
      const suppliersTable = inputData.input.filter(item => item.name === 'suppliers.csv')
      const productionPoliciesTable = inputData.input.filter(item => item.name === 'production_policies.csv')
      const metrics = inputData.metrics
      let averageSupplierDistance = 0
      const longestLeadTimeSupplier = {
        name: '',
        distance: 0
      }

      metrics.forEach((value, index) => { metrics[index].value = 0 })

      if (typeof facilitiesTable[0] !== 'undefined') {
        facilitiesTable[0].file.data.forEach(facility => {
          if (checkFacilitiesFilters(facility)) {
            if (facility[1].match(/SUP_/)) {
              metrics[0].value += 1
            }
            if (facility[1].match(/ODC_/)) {
              metrics[1].value += 1
            }
            const distance = getDistance([facility[8], facility[9]], DCHAMTRAMCK)
            averageSupplierDistance += distance

            if (longestLeadTimeSupplier.distance < distance) {
              longestLeadTimeSupplier.name = facility[1]
              longestLeadTimeSupplier.distance = distance
            }
          }
        })

        if (typeof suppliersTable[0] !== 'undefined') {
          suppliersTable[0].file.data.forEach(supplier => {
            if (checkFacilitiesFilters(supplier)) {
              if (supplier[1].match(/SUP_/)) {
                metrics[0].value += 1
              }
              if (supplier[1].match(/ODC_/)) {
                metrics[1].value += 1
              }
              const distance = getDistance([supplier[7], supplier[8]], DCHAMTRAMCK)
              averageSupplierDistance += distance

              if (longestLeadTimeSupplier.distance < distance) {
                longestLeadTimeSupplier.name = supplier[1]
                longestLeadTimeSupplier.distance = distance
              }
            }
          })
        }

        const averageSupplierDistanceToPlant = decimalNumber(averageSupplierDistance / (facilitiesTable[0].file.data.length + suppliersTable[0].file.data.length))
        metrics[2].value = `${averageSupplierDistanceToPlant} miles`
        metrics[3].value = `${longestLeadTimeSupplier.name} ${decimalNumber(Number(longestLeadTimeSupplier.distance))} miles`

        const longestLeadTimePart = []
        if (typeof productionPoliciesTable[0] !== 'undefined') {
          productionPoliciesTable[0].file.data.forEach(productionPolice => {
            if (productionPolice[1] === longestLeadTimeSupplier.name) {
              longestLeadTimePart.push(productionPolice[2])
            }
          })
        }

        metrics[4].value = longestLeadTimePart.length
      }

      dispatch(setInputState(SET_METRICS, metrics))
    },
    map: showArches => {
      return inputMap(showArches)
    },
    setFilters,
    suppliersWithBandOfDistance,
    suppliersWithPartQuantityBand
  }
}

export default InputDataService
