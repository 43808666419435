const SET_SCENARIO_DATA = 'SET_SCENARIO_DATA'
const SET_SCENARIOS = 'SET_SCENARIOS'
const SELECT_SCENARIO = 'SELECT_SCENARIO'
const RESET_SCENARIO_STATE = 'RESET_SCENARIO_STATE'

const setScenario = value => {
  return { type: SET_SCENARIO_DATA, value }
}

const selectScenario = value => {
  return { type: SELECT_SCENARIO, value }
}

const setScenarios = value => {
  return { type: SET_SCENARIOS, value }
}

const resetScenarioState = () => {
  return { type: RESET_SCENARIO_STATE }
}

export {
  SET_SCENARIO_DATA,
  SET_SCENARIOS,
  SELECT_SCENARIO,
  RESET_SCENARIO_STATE,
  setScenario,
  setScenarios,
  selectScenario,
  resetScenarioState
}
