import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import TopBlock from '../components/Blocks/TopBlock'
import CombineMetrics from '../components/BaselineSummary/CombineMetrics'
import CombineGraphs from '../components/BaselineSummary/CombineGraphs'
import Map from '../components/Map/Map'
import Chart from '../components/Chart/Chart'
import XyChart from '../components/XyChart/XyChart'
import FiltersAndExport from '../components/FiltersAndExport/FiltersAndExport'
import ViewOutputMetrics from '../components/ViewOutputMetrics/ViewOutputMetrics'
import Cost from '../components/OutputMetricsDataComponents/Cost'
import Risk from '../components/OutputMetricsDataComponents/Risk'
import Mode from '../components/OutputMetricsDataComponents/Mode'
import Legend from '../components/OutputMetricsDataComponents/Legend'
import CustomButton from '../components/Buttons/CustomButton'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import InventoryChart from '../components/InventoryChart/InventoryChart'
import Data from '../services/Data'
import { useHistory } from 'react-router-dom'
import Timer from '../components/Timer/Timer'
import ModalChooseScenario from '../components/Modals/ModalChooseScenario'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import { setAlternativeSuppliers, checkIsSetFilter } from '../redux/actions/FiltersActions'
import { useSelector, useDispatch } from 'react-redux'
import { SET_TYPE, setState as setJobState } from '../redux/actions/JobActions'
import { selectScenario } from '../redux/actions/ScenarioActions'
import exportCSV from '../services/Data/ExportDataServices'
import MapLegend from '../components/Map/MapLegend'
import ArcSwitcher from '../components/ArcSwitcher/ArcSwitcher'
import Box from '@material-ui/core/Box'
import LineChart from '../components/LineChart/LineChart'

function Scenario () {
  const dataSet = Data()

  useEffect(() => {
    dataSet.setInputData()
    dataSet.setOutputData()
    dataSet.setScenario()
  }, [])

  const [showArcs, setShowArcs] = useState(false)
  const [isOpenOutputChart, setOpenChart] = useState(false)
  const [open, setOpen] = useState(false)
  const inputData = useSelector(state => state.InputReducer)
  const outputData = useSelector(state => state.OutputReducer)
  const scenarioData = useSelector(state => state.ScenarioReducer)
  const filtersData = useSelector(state => state.FilterReducer)
  const jobData = useSelector(state => state.JobReducer)

  const dispatch = useDispatch()
  const history = useHistory()

  const [timerIsStarted, setTimerIsStarted] = useState(false)

  const { jobStatus, currentJob, jobKey } = jobData
  const jobType = jobData.type

  useEffect(() => {
    if (jobKey) {
      dataSet.stopTimer()
      dataSet.startTimer()
      setTimerIsStarted(true)
    }
  }, [jobKey])

  useEffect(() => {
    if (jobStatus && jobKey && jobType === 3) {
      const action = () => {
        dataSet.setScenario().then(() => {
          dataSet.setNavigationStateGoNext(2, () => {
            setTimeout(() => {
              if (window.location.pathname === '/scenario') {
                window.location.reload()
              } else {
                history.push('/scenario')
              }
            }, 1000)
          })
        })
      }
      dataSet.checkJob(action)
    }
  }, [jobStatus, jobKey])

  useEffect(() => {
    if (jobStatus) {
      const action = () => {
        dataSet.startJob()
      }
      dataSet.updateScenarioConfig(scenarioData.current, action)
    }
  }, [jobStatus, currentJob])

  useEffect(() => {
    if (!outputData.output.length) {
      history.push('/output')
    }
    if (!inputData.input.length) {
      history.push('/input')
    }
  })

  const changeShowArcs = () => { setShowArcs(!showArcs) }
  const handleOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  useEffect(() => {
    dataSet.Output.setFilters()
  }, [outputData.output])

  const changeScenario = scenario => {
    dispatch(setJobState(SET_TYPE, 3))
    dispatch(selectScenario(scenario))
    dataSet.updateScenarioConfig(scenario, dataSet.startJob)
  }

  const {
    coordinates,
    lines
  } = dataSet.Output.map(showArcs)

  const scenarioMap = dataSet.Scenario.map(showArcs)

  if (!scenarioData.scenario.output.length || !scenarioData.scenario.input.length) {
    return <></>
  }

  return (
        <React.Fragment>
            <Header>
              <React.Fragment>
                  {
                      timerIsStarted
                        ? <Timer stopHandler={() => { setTimerIsStarted(false) }} />
                        : <CustomButton action={handleOpen} title='change scenario'><EditRoundedIcon /></CustomButton>
                  }
                <ModalChooseScenario
                  title='Change Scenario'
                  scenarios={dataSet.scenarios}
                  runFunction={changeScenario}
                  open={open}
                  closeFunction={handleClose}
                />
              </React.Fragment>
            </Header>
            <TopBlock title='Scenario Output'>
              <div style={{ display: 'flex' }}>
                <FiltersAndExport exportFunc={() => exportCSV(scenarioData.scenario)} />
                <CustomButton
                  action={ () => { setOpenChart(true) }}
                  title='simulate scenario'
                  colored
                  disabled={isOpenOutputChart}
                >
                  <PlayArrowRoundedIcon/>
                </CustomButton>
              </div>
            </TopBlock>
            <Box display='flex'>
                <CombineMetrics title='Metrics'>
                  <ViewOutputMetrics>
                    {[{
                      tabName: 'Cost',
                      tabBody: <Cost costs={dataSet.Scenario.costs(scenarioData.current)} isSetFilter={checkIsSetFilter(filtersData.filters)}>
                          <div>
                            <Legend data={dataSet.Output.costsChartDataLegend()} justify='flex-end' cuted={false}/>
                            <div>
                              <LineChart config={dataSet.Scenario.costsChartData().config} data={dataSet.Scenario.costsChartData().data} containerId='chartdiv4'/>
                            </div>
                          </div>
                        </Cost>
                    },
                    {
                      tabName: 'Mode',
                      tabBody: <div>
                        <Legend data={dataSet.Scenario.modesChartLegend()} justify='flex-end' cuted={false}/>
                        {
                          dataSet.Scenario.modesChartData().map((mode, key) =>
                              <Mode key={key} title={mode.title} chartName={mode.chartName}
                                    config={mode.config} data={mode.data} containerId={mode.containerId} />
                          )
                        }
                      </div>
                    },
                    {
                      tabName: 'Risk',
                      tabBody: <Risk risks={dataSet.Scenario.risks()} isSetFilter={checkIsSetFilter(filtersData.filters)}/>
                    }]}
                  </ViewOutputMetrics>
                </CombineMetrics>
                <CombineGraphs
                    arcSwitcher={
                      <ArcSwitcher
                        checkedShow={showArcs}
                        changeShowArcs={changeShowArcs}
                        checkedAlternative={filtersData.showAlternativeSuppliers}
                        changeAlternative={() => { dispatch(setAlternativeSuppliers((!filtersData.showAlternativeSuppliers))) }}
                      />
                    }
                    map={
                      <div>
                        <MapLegend/>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: 24, width: '100%' }}>
                                <Map showArcs={showArcs} coordinates={coordinates} lines={lines} containerId="chartdiv-m-1"/>
                            </div>
                            <div style={{ width: '100%' }}>
                                <Map showArcs={showArcs} coordinates={scenarioMap.coordinates} lines={scenarioMap.lines} containerId="chartdiv-m-2" syncId="chartdiv-m-1" />
                            </div>
                        </div>
                      </div>

                    }
                    leftChart={
                      {
                        title: 'Supplier Distance Histogram',
                        legend: true,
                        content: <Chart key='1' titles={{ left: 'Suppliers', bottom: 'Distance band' }} data={dataSet.Scenario.suppliersWithBandOfDistance()} containerId="chartdiv2" />
                      }
                    }
                    rightChart={
                      {
                        title: 'Parts per Supplier Histogram',
                        legend: true,
                        content: <Chart key='1' titles={{ left: 'Suppliers', bottom: 'Parts quantity band' }} data={dataSet.Scenario.suppliersWithPartQuantityBand()} containerId="chartdiv3" />
                      }
                    }
                    inventoryChart={
                      isOpenOutputChart
                        ? <InventoryChart title='Inventory'>
                            <XyChart data={dataSet.Scenario.inventory()} containerId="chartdiv-chart-3" />
                          </InventoryChart>
                        : null
                    }
                />
            </Box>
        </React.Fragment>
  )
}

export default Scenario
