const SET_STATUS = 'SET_STATUS'
const SET_SCRIPTS = 'SET_SCRIPTS'
const SET_CURRENT_JOB = 'SET_CURRENT_JOB'
const SET_JOB_KEY = 'SET_JOB_KEY'
const SET_TYPE = 'SET_TYPE'

const setState = (type, value) => {
  return { type, value }
}

export {
  setState,
  SET_STATUS,
  SET_SCRIPTS,
  SET_CURRENT_JOB,
  SET_JOB_KEY,
  SET_TYPE
}
