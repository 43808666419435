import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PersonOutline from '@material-ui/icons/PersonOutline'
import { theme } from '../../themeProvider'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3, 2)
  }
}))

export default function GlobalPanel () {
  const classes = useStyles()

  return (
    <Box className={classes.root} display="flex" flexDirection='column' alignItems='center' justifyContent='space-between' flex='0 auto'>
      <img src="../Logo.svg" alt="optilogic" style={ theme.components.logtype }></img>
      <PersonOutline/>
    </Box>
  )
}
