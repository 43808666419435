import React, { useState } from 'react'
import Data from '../../services/Data'
import CombineList from './CombineList'
import CombineGrid from './CombineGrid'
import FlexDataContainer from './FlexDataContainer'
import CustomTable from '../Table/CustomTable'

function BaselineData () {
  const dataSet = Data()

  const [selectedTable, setSelectedTable] = useState(0)

  const selectTable = key => {
    if (key !== selectedTable) {
      setSelectedTable(key)
    }
  }

  const { columns, data } = dataSet.Input.baselineTable(selectedTable)
  const [filtredData, setfiltredData] = useState(data)
  const [filterRow, setFilterRow] = useState('')

  const dataAfterFilter = filter(data, filterRow)

  if (JSON.stringify(filtredData) !== JSON.stringify(dataAfterFilter)) {
    setfiltredData(dataAfterFilter)
  }

  const TableList = dataSet.Input.baselineTableNames()

  const onApply = (columnNumber, rowNumber, newValue) => { dataSet.updateInputData(selectedTable, columnNumber, rowNumber, newValue) }

  const addRow = (nextRowNum) => { dataSet.addEmptyRow(selectedTable, nextRowNum) }

  const duplicateRow = (nextRowNum) => { dataSet.duplicateRow(selectedTable, nextRowNum) }

  const deleteRow = (nextRowNum) => { dataSet.deleteRow(selectedTable, nextRowNum) }

  function filter (data, inputText) {
    return data.map(row => row.filter(cell => cell.toLowerCase().includes(inputText.toLowerCase())).length > 0 ? row : false).filter(row => row)
  }

  const filterData = (inputText) => { setFilterRow(inputText) }

  return (
      <FlexDataContainer>
        <CombineList tableList={TableList} selectedTable={selectedTable} selectOneTable={selectTable}/>
        <CombineGrid>
          <CustomTable
            tableName={TableList[selectedTable]}
            columns={columns}
            data={filtredData}
            onApply={onApply}
            addRow={addRow}
            duplicateRow={duplicateRow}
            deleteRow={deleteRow}
            filterData={filterData}
          />
        </CombineGrid>
      </FlexDataContainer>
  )
}

export default React.memo(BaselineData, (props, nextProps) => {
  if (JSON.stringify(props) === JSON.stringify(nextProps)) {
    return true
  }
})
