import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Done from '@material-ui/icons/Done'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  navigationPanel: {
    width: 'fit-content'
  },
  oneTab: {
    width: theme.components.tab.width,
    height: theme.components.tab.height,
    '& span': {
      alignItems: 'inherit',
      flexDirection: 'inherit',
      '& span': {
        marginLeft: theme.spacing(2)
      }
    }
  },
  circleCommon: {
    width: theme.components.navigatioIcons.size,
    height: theme.components.navigatioIcons.size,
    color: theme.components.navigatioIcons.color,
    fontSize: theme.components.navigatioIcons.fontSize,
    borderRadius: theme.components.navigatioIcons.borderRadius,
    lineHeight: theme.components.navigatioIcons.lineHeight
  },
  circleGrey: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.components.navigatioIcons.inactiveColor
  },
  circleColored: {
    width: theme.components.navigatioIcons.wideSize,
    height: theme.components.navigatioIcons.wideSize,
    marginRight: theme.spacing(2),
    border: '1px solid',
    borderColor: theme.components.navigatioIcons.activeColor,
    borderRadius: theme.components.navigatioIcons.borderRadius,
    '& div': {
      backgroundColor: theme.components.navigatioIcons.activeColor
    }
  },
  circleDone: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.components.navigatioIcons.activeColor,
    '& svg': {
      fontSize: theme.components.navigatioIcons.wideFontSize
    }
  }
}))

function Navigation () {
  const classes = useStyles()

  const history = useHistory()
  const navigation = useSelector(state => state.NavigationReducer)
  let nowRout
  const tabsBody = []
  let currentIcon
  let isCurrentDisable = false

  const getCircleGrey = (value) => <div className={`${classes.circleCommon} ${classes.circleGrey}`}>{value}</div>
  const getCircleDone = () => (
    <Box display='flex' alignItems='center' justifyContent='center' className={`${classes.circleCommon} ${classes.circleDone}`}>
      <Done />
    </Box>
  )
  const getCircleColored = (value) => (
    <Box className={classes.circleColored} display='flex' alignItems='center' justifyContent='center'>
      <div className={classes.circleCommon}>
        {value}
      </div>
    </Box>
  )

  for (const tab of navigation.navigation) {
    if (tab.href === history.location.pathname) {
      nowRout = tab.id
    }

    if (tab.id === nowRout) {
      currentIcon = getCircleColored(tab.value)
    } else if (tab.validated === false) {
      currentIcon = getCircleGrey(tab.value)
      isCurrentDisable = true
    } else {
      currentIcon = getCircleDone()
    }

    const title = <Typography variant="subtitle1" gutterBottom style={{ textTransform: 'none' }}> {tab.name} </Typography>

    tabsBody.push(
      <Tab
        className={classes.oneTab}
        component="a"
        label={title}
        href={tab.href}
        icon={currentIcon}
        disabled={isCurrentDisable}
        onClick={(event) => {
          event.preventDefault()
          history.push(tab.href)
        }}
        key={tab.id}
        id={`nav-tab-${tab.id}`}
        aria-controls={`nav-tabpanel-${tab.id}`}
      />
    )
  }

  return (
        <Tabs
          variant="scrollable"
          value={nowRout}
          textColor="primary"
          indicatorColor="primary"
          aria-label="scrollable force tabs example"
          className={classes.navigationPanel}
        >
          {tabsBody}
        </Tabs>
  )
}

export default React.memo(Navigation, (props, nextProps) => {
  if (JSON.stringify(props) === JSON.stringify(nextProps)) {
    return true
  }
})
