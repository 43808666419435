import { SET_OUTPUT_DATA, RESET_OUTPUT_STATE } from '../actions/OutputActions'

const initialState = {
  output: []
}

export default function OutputReducer (state = initialState, action) {
  switch (action.type) {
    case SET_OUTPUT_DATA:
      return {
        ...state,
        output: action.value
      }
    case RESET_OUTPUT_STATE:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
