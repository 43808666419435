import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.components.metrics.width,
    margin: theme.spacing(0, 3),
    height: 'fit-content'
  },
  header: {
    padding: theme.spacing(0),
    margin: theme.spacing(3, 3, 2, 3)
  },
  content: {
    padding: theme.spacing(0, 3)
  },
  title: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight
  }
}))

function CombineMetrics ({ title, children }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          className: classes.title
        }}
        className={classes.header}
      />
      <CardContent className={classes.content}>
        {children}
      </CardContent>
    </Card>
  )
}

CombineMetrics.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string
}

export default CombineMetrics
