import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import IconButton from '@material-ui/core/IconButton'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'

function TableTitle ({ title, handleSearch, counter }) {
  const controlPanel = <InputAdornment position="start"><SearchRoundedIcon/></InputAdornment>

  return (
      <React.Fragment>
    <Typography variant="h2" id="tableTitle" component="h2" style={{ marginBottom: 24 }}>{title}</Typography>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
            <TextField
                defaultValue={''}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={{ startAdornment: controlPanel }}
            />
            <IconButton disabled><FilterListRoundedIcon/></IconButton>
        </div>

        <Typography variant="caption" display="block" gutterBottom>
            Rows in total: {counter}
        </Typography>

    </div>

    </React.Fragment>
  )
}

TableTitle.propTypes = {
  title: PropTypes.string,
  handleSearch: PropTypes.func,
  counter: PropTypes.number
}

export default TableTitle
