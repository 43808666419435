import { SET_ALTERNATIVE_SUPPLIERS, SET_FILTERS } from '../actions/FiltersActions'

const initialState = {
  showAlternativeSuppliers: true,
  filters: {
    input: {
      products: {
        label: 'Product',
        values: [],
        value: []
      },
      programs: {
        label: 'Program',
        values: [],
        value: []
      },
      suppliers: {
        label: 'Supplier',
        values: [],
        value: []
      }
    },
    output: {
      modes: {
        label: 'Mode',
        values: [],
        value: []
      },
      products: {
        label: 'Product',
        values: [],
        value: []
      },
      programs: {
        label: 'Program',
        values: [],
        value: []
      },
      suppliers: {
        label: 'Supplier',
        values: [],
        value: []
      }
    }
  }
}

export default function FiltersReducer (state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.value
      }
    case SET_ALTERNATIVE_SUPPLIERS:
      return {
        ...state,
        showAlternativeSuppliers: action.value
      }
    default:
      return state
  }
}
