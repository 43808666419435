import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import LinearProgress from '@material-ui/core/LinearProgress'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import CustomButton from '../Buttons/CustomButton'
import { setScenarios } from '../../redux/actions/ScenarioActions'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Data from '../../services/Data'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    outline: 'none',
    width: theme.components.modals.width,
    height: theme.components.modals.height
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0)
  },
  cardHeaderaAction: {
    color: theme.palette.primary.contrastText
  },
  cardContent: {
    padding: theme.spacing(3)
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  cardActions: {
    padding: theme.spacing(0, 3, 3, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight
  }
}))

function ModalAddScenario ({ openAddScenario, closeFunc, onCreate, runScenario }) {
  const classes = useStyles()
  const [textedScenario, setTextedScenario] = useState('')
  const [creating, setCreating] = useState(false)
  const dispatch = useDispatch()
  const dataSet = Data()

  const scenarioData = useSelector(state => state.ScenarioReducer)

  const handleChange = (event) => { setTextedScenario(event.target.value) }

  const handleActivateScenario = action => {
    setCreating(true)
    const scenarios = scenarioData.scenarios
    scenarios.push(textedScenario)
    dispatch(setScenarios(scenarios))
    onCreate(textedScenario)
    setTextedScenario('')
    dataSet.createScenario(textedScenario).then(() => {
      setCreating(false)
      closeFunc()
      if (typeof action === 'function') {
        action(textedScenario)
      }
    })
  }

  useEffect(() => {
    setCreating(false)
  }, [])

  const handleClear = () => {
    setTextedScenario('')
  }

  const handleApply = () => {
    handleActivateScenario(runScenario)
  }

  const controlPanel = (
    <div style={{ display: 'flex' }}>
      <IconButton
        onClick={handleActivateScenario}
        disabled={ !textedScenario }
      >
        <CheckRoundedIcon/>
      </IconButton >

      <IconButton
        onClick={handleClear}
        disabled={ !textedScenario }
      >
        <CloseRoundedIcon/>
      </IconButton >
    </div>
  )

  return (
      <Modal open={openAddScenario} className={classes.root}>
          <Card className={classes.card}>
            <CardHeader
              action={
                <IconButton
                  onClick={() => closeFunc()}
                  aria-label="settings"
                  className={ classes.cardHeaderaAction }
                >
                  <Close />
                </IconButton>
              }
              title='Add Scenario'
              color='primary'
              titleTypographyProps={{
                className: classes.title
              }}
              component='h4'
              className={classes.cardHeader}
            />
            <CardContent className={classes.cardContent}>
              {
                creating
                  ? <LinearProgress />
                  : <TextField
                        id="outlined-basic"
                        label="Scenario"
                        variant="outlined"
                        type='input'
                        size='small'
                        margin='dense'
                        inputProps={{
                          style: { height: 36, padding: '0 0 0 12px' }
                        }}
                        value={textedScenario}
                        onChange={handleChange}
                        className={classes.textField}
                        InputProps={{
                          endAdornment: controlPanel
                        }}
                    />
              }
            </CardContent>
            {
              !creating && <CardActions className={classes.cardActions}>
                <CustomButton action={closeFunc} title='Cancel'/>
                <CustomButton title="Apply" colored onClick={ handleApply } disabled={ !textedScenario.length }/>
              </CardActions>
            }
          </Card>
      </Modal>
  )
}

ModalAddScenario.propTypes = {
  openAddScenario: PropTypes.bool,
  closeFunc: PropTypes.func,
  onCreate: PropTypes.func,
  runScenario: PropTypes.func
}

export default ModalAddScenario
