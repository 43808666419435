import { SET_TIMER, SET_JOB_TIMER } from '../actions/TimerActions'

const initialState = {
  seconds: 0,
  jobTimer: null
}

export default function TimerReducer (state = initialState, action) {
  switch (action.type) {
    case SET_TIMER:
      return {
        ...state,
        seconds: action.value
      }
    case SET_JOB_TIMER:
      return {
        ...state,
        jobTimer: action.value
      }
    default:
      return state
  }
}
