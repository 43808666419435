import { SET_CURRENT_JOB, SET_JOB_KEY, SET_SCRIPTS, SET_STATUS, SET_TYPE } from '../actions/JobActions'

const initialState = {
  jobStatus: 0,
  scripts: [
    // eslint-disable-next-line no-undef
    env.SOLVE_MODEL_JOB_FILE
  ],
  currentJob: 0,
  type: 1, // 1 - input, 2 - output, 3 - scenario
  jobKey: ''
}

export default function JobReducer (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_JOB:
      return {
        ...state,
        currentJob: action.value
      }
    case SET_STATUS:
      return {
        ...state,
        jobStatus: action.value
      }
    case SET_JOB_KEY:
      return {
        ...state,
        jobKey: action.value
      }
    case SET_SCRIPTS:
      return {
        ...state,
        scripts: action.value
      }
    case SET_TYPE:
      return {
        ...state,
        type: action.value
      }
    default:
      return state
  }
}
