import axios from 'axios'

const API_DOMAIN = 'https://api.optilogic.app'
const API_KEY_REFRESH_URL = '/refreshApiKey'
const WORKSPACE_FILES_TEMPLATE_URL = '/v0/{workspace}/files'
const WORKSPACE_FILE_TEMPLATE_URL = '/v0/{workspace}/file/{directory}/{filename}?op=download'
const CREATE_JOB_URL = '/v0/{workspace}/job?directoryPath={directory}&filename={filename}'
const GET_JOB_URL = '/v0/{workspace}/job/{jobKey}?op={type}'
const DELETE_JOB_URL = '/v0/{workspace}/job/{jobKey}'
const UPDATE_FILE_URL = '/v0/{workspace}/file/{directory}/{filename}?overwrite=true'
const COPY_FILE_URL = '/v0/{workspace}/file/copy?sourceDirectoryPath={sourceDirectoryPath}&sourceFilename={sourceFilename}&targetDirectoryPath={targetDirectoryPath}&targetFilename={targetFilename}&overwrite={overwrite}'

const API = {
  login: ({ email, password }) => {
    return axios({
      url: API_DOMAIN + API_KEY_REFRESH_URL,
      method: 'post',
      headers: {
        'X-USER-ID': email,
        'X-USER-PASSWORD': password
      }
    })
  },
  getWorkspaceFiles: ({ apiKey, workspace, filter = '' }) => {
    return axios({
      url: API_DOMAIN + WORKSPACE_FILES_TEMPLATE_URL.replace('{workspace}', workspace) + '?filter=' + filter,
      method: 'get',
      headers: {
        'X-API-KEY': apiKey
      }
    })
  },
  getWorkspaceFile: ({ apiKey, workspace, directory, filename }) => {
    return axios({
      url: API_DOMAIN + WORKSPACE_FILE_TEMPLATE_URL
        .replace('{workspace}', workspace)
        .replace('{directory}', directory)
        .replace('{filename}', filename),
      method: 'get',
      headers: {
        'X-API-KEY': apiKey
      }
    })
  },
  createJob: ({ apiKey, workspace, directory, filename }) => {
    return axios({
      url: API_DOMAIN + CREATE_JOB_URL.replace('{workspace}', workspace)
        .replace('{directory}', directory).replace('{filename}', filename),
      method: 'post',
      headers: {
        'X-API-KEY': apiKey
      }
    })
  },
  stopJob: ({ apiKey, workspace, jobKey }) => {
    return axios({
      url: API_DOMAIN + DELETE_JOB_URL.replace('{workspace}', workspace)
        .replace('{jobKey}', jobKey),
      method: 'delete',
      headers: {
        'X-API-KEY': apiKey
      }
    })
  },
  getJob: ({ apiKey, workspace, jobKey, type = 'status' }) => {
    return axios({
      url: API_DOMAIN + GET_JOB_URL.replace('{workspace}', workspace)
        .replace('{jobKey}', jobKey).replace('{type}', type),
      method: 'get',
      headers: {
        'X-API-KEY': apiKey
      }
    })
  },
  updateFile: ({ apiKey, workspace, directory, filename, file }) => {
    return axios({
      url: API_DOMAIN + UPDATE_FILE_URL.replace('{workspace}', workspace)
        .replace('{directory}', directory).replace('{filename}', filename),
      method: 'post',
      data: file,
      headers: {
        'X-API-KEY': apiKey,
        'content-type': 'application/octet-stream'
      }
    })
  },
  copyFile: ({ apiKey, workspace, sourceDirectoryPath, sourceFilename, targetDirectoryPath, targetFilename }) => {
    return axios({
      url: API_DOMAIN + COPY_FILE_URL.replace('{workspace}', workspace)
        .replace('{sourceDirectoryPath}', sourceDirectoryPath)
        .replace('{sourceFilename}', sourceFilename)
        .replace('{targetDirectoryPath}', targetDirectoryPath)
        .replace('{targetFilename}', targetFilename)
        .replace('{overwrite}', 'true'),
      method: 'post',
      headers: {
        'X-API-KEY': apiKey
      }
    })
  }
}

export default API
