import React, { useCallback, useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import PropTypes from 'prop-types'
// eslint-disable-next-line camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
// eslint-disable-next-line camelcase
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz'

function Chart (props) {
  const {
    containerId,
    data,
    titles = {
      left: '',
      bottom: ''
    }
  } = props

  const drawXyChart = useCallback(() => {
    if (!data.length) {
      return <></>
    }
    am4core.useTheme(am4themes_animated)
    am4core.useTheme(am4themes_dataviz)

    // Create chart instance
    const chart = am4core.create(containerId, am4charts.XYChart)

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'name'
    categoryAxis.title.text = titles.bottom
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 20

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = titles.left

    chart.data = data

    const createSeries = (field, name, fill) => {
      const series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = field
      series.dataFields.categoryX = 'name'
      series.name = name
      series.columns.template.fill = fill
      series.columns.template.strokeWidth = 0

      const hs = series.columns.template.states.create('active')
      hs.properties.fillOpacity = 1

      series.columns.template.events.on('hit', highlighColumn)

      return series
    }

    const highlighColumn = ev => {
      chart.series.each(function (series) {
        if (series instanceof am4charts.ColumnSeries) {
          series.columns.each(function (column) {
            column.isActive = column.dataItem.categoryX === ev.target.dataItem.category
          })
        }
      })
    }

    const series = createSeries('value', 'Value', '#44BD7E')

    // Add events
    categoryAxis.renderer.labels.template.events.on('hit', highlighColumn)
    categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer

    const getLabelFill = (color, target) => {
      const black = '#000000'
      // const white = '#FFFFFF'

      if (!target.dataItem) {
        return black
      }

      return target.dataItem.values.valueY.value > 4 ? black : black // white black
    }

    const getLabelLocationY = (color, target) => {
      const inside = 0// 0.05
      const outside = 0// -0.05

      if (!target.dataItem) {
        return outside
      }

      return target.dataItem.values.valueY.value > 4 ? inside : outside
    }

    if (typeof data[0].value2 !== 'undefined') {
      const series2 = createSeries('value2', 'Value2', '#616FF6')

      const bullet = series2.bullets.push(new am4charts.LabelBullet())
      const label = bullet.label
      label.text = '{valueY}'
      bullet.label.adapter.add('fill', getLabelFill)
      bullet.adapter.add('locationY', getLabelLocationY)
    }

    const bullet = series.bullets.push(new am4charts.LabelBullet())
    const label = bullet.label
    label.text = '{valueY}'
    bullet.label.adapter.add('fill', getLabelFill)
    bullet.adapter.add('locationY', getLabelLocationY)
  }, [containerId, data, titles])

  useEffect(() => {
    drawXyChart()
  }, [containerId, data, titles])

  return (
    <div className='chart-container' id={containerId} />
  )
}

Chart.propTypes = {
  containerId: PropTypes.string,
  data: PropTypes.array,
  titles: PropTypes.object
}

export default React.memo(Chart, (props, nextProps) => {
  return JSON.stringify(props) === JSON.stringify(nextProps)
})
