import OutputDataService from './OutputDataService'
import { useSelector } from 'react-redux'

const ScenarioDataService = () => {
  const scenarioService = OutputDataService({ scenario: true })
  const outputService = OutputDataService()

  const scenarioData = useSelector(state => state.ScenarioReducer)

  const suppliersWithBandOfDistanceData = scenarioService.suppliersWithBandOfDistance()
  scenarioService.suppliersWithBandOfDistance = () => {
    const data = outputService.suppliersWithBandOfDistance()
    data.forEach((value, index) => {
      data[index].value2 = suppliersWithBandOfDistanceData[index].value
    })
    return data
  }

  const suppliersWithPartQuantityBandData = scenarioService.suppliersWithPartQuantityBand()
  scenarioService.suppliersWithPartQuantityBand = () => {
    const data = outputService.suppliersWithPartQuantityBand()
    data.forEach((value, index) => {
      data[index].value2 = suppliersWithPartQuantityBandData[index].value
    })
    return data
  }

  const getScenarioCosts = scenarioService.costs

  scenarioService.costs = (scenario = 'Baseline') => {
    const scenarioCosts = getScenarioCosts(scenario)

    const data = outputService.costs()
    data.forEach((value, index) => {
      data[index].scenarioPrice = scenarioCosts[index].price
      data[index].scenarioPercent = scenarioCosts[index].percent
    })
    return data
  }

  const scenarioModesChartData = scenarioService.modesChartData()
  scenarioService.modesChartData = () => {
    const data = outputService.modesChartData()
    data.forEach((value, index) => {
      const scenario = scenarioModesChartData[index].data[0]
      scenario.name = scenarioData.current
      data[index].data[1] = data[index].data[0]
      data[index].data[0] = scenario
    })
    return data
  }

  const scenarioCostChartData = scenarioService.costsChartData()
  scenarioService.costsChartData = () => {
    const data = outputService.costsChartData()

    data.data.forEach((value, index) => {
      const scenario = scenarioCostChartData.data[index]
      scenario.name = scenarioData.current

      data.data[1] = data.data[0]
      data.data[0] = scenario
    })
    return data
  }

  const scenarioRisks = scenarioService.risks()
  scenarioService.risks = () => {
    const data = outputService.risks()
    data.forEach((value, index) => {
      data[index].scenarioPrice = scenarioRisks[index].price
      data[index].scenarioPercentage = scenarioRisks[index].percentage
    })
    return data
  }

  scenarioService.inventory = () => {
    return [{
      date: new Date(2021, 4, 24),
      value0: 800,
      value1: 700,
      value2: 650
    }, {
      date: new Date(2021, 4, 25),
      value0: 1200,
      value1: 650,
      value2: 450
    }, {
      date: new Date(2021, 4, 26),
      value0: 450,
      value1: 800,
      value2: 600
    }, {
      date: new Date(2021, 4, 27),
      value0: 750,
      value1: 1100,
      value2: 600
    }, {
      date: new Date(2021, 4, 28),
      value0: 600,
      value1: 550,
      value2: 550
    }, {
      date: new Date(2021, 4, 29),
      value0: 700,
      value1: 420,
      value2: 650
    }, {
      date: new Date(2021, 4, 30),
      value0: 600,
      value1: 520,
      value2: 420
    }]
  }

  return scenarioService
}

export default ScenarioDataService
