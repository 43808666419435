import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  onePoint: {
    fontFamily: theme.components.legend.fontFamily,
    fontSize: theme.components.legend.fontSize,
    marginRight: theme.spacing(2)
  },
  coloredCircle: {
    width: theme.components.legend.size,
    height: theme.components.legend.size,
    borderRadius: theme.components.legend.borderRadius,
    marginRight: theme.spacing(2)
  }
}))

function Legend ({ data, justify, cuted }) {
  const classes = useStyles()
  const legendBody = data.map((element) => {
    if (cuted && +element.value > 0) {
      return (
        <Box key={element.color} className={classes.onePoint} display='flex' alignItems='center'>
          <div className={classes.coloredCircle} style={{ backgroundColor: element.color }} />
          <span>{element.field}</span>
        </Box>
      )
    } else if (!cuted) {
      return (
        <Box key={element.color} className={classes.onePoint} display='flex' alignItems='center'>
          <div className={classes.coloredCircle} style={{ backgroundColor: element.color }} />
          <span>{element.field}</span>
        </Box>
      )
    } else {
      return null
    }
  })

  return (
    <Box display='flex' alignItems='center' justifyContent={justify}>
      {legendBody}
    </Box>
  )
}

Legend.propTypes = {
  data: PropTypes.array,
  justify: PropTypes.string,
  cuted: PropTypes.bool
}

export default Legend
