const SET_FILTERS = 'SET_FILTERS'
const SET_ALTERNATIVE_SUPPLIERS = 'SET_ALTERNATIVE_SUPPLIERS'

const setFilters = value => {
  return { type: SET_FILTERS, value }
}

const setAlternativeSuppliers = value => {
  return { type: SET_ALTERNATIVE_SUPPLIERS, value }
}

const checkIsSetFilter = filters => {
  // {
  //   input: {
  //     products: {…},
  //     programs: {…},
  //     suppliers: {…}
  //   },
  //   output: {
  //     modes: {
  //   label: ...,
  //   value: [{...}, {...}], !!!!!!!!!!!!!
  //   values: ...
  // },
  //     products: {
  //   label: ...,
  //   value: [{...}, {...}], !!!!!!!!!!!!
  //   values: ...
  // },
  //     programs: {
  //     label: ...,
  //     value: [{...}, {...}], !!!!!!!!!!!!!!!
  //     values: ...
  // },
  //     suppliers: {
  //   label: ...,
  //   value: [{...}, {...}], !!!!!!!!!!!!!
  //   values: ...
  // }
  //   }
  // }
  let isAddedFilter = false

  for (const filterCategory in filters.output) {
    if (filters.output[filterCategory].value.length > 0) {
      isAddedFilter = true
    }
  }
  return isAddedFilter
}

export {
  setFilters,
  setAlternativeSuppliers,
  checkIsSetFilter,
  SET_FILTERS,
  SET_ALTERNATIVE_SUPPLIERS
}
