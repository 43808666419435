import API from '../../services/API'
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'

const login = ({ email, password }) => {
  return dispatch => {
    return API.login({ email, password })
      .then(response => {
        dispatch({
          type: SET_AUTH_TOKEN,
          value: response.data.apiKey
        })
      })
  }
}

export {
  login,
  SET_AUTH_TOKEN
}
