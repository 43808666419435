import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 3)
  },
  selected: {
    backgroundColor: theme.palette.primary.lighten2,
    borderLeft: '4px solid',
    borderColor: theme.palette.primary.lighten1
  },
  default: {
    borderLeft: '4px solid rgba(0, 0, 0, 0)'
  }
}))

function CombineList ({ tableList, selectedTable, selectOneTable }) {
  const classes = useStyles()

  const listBody = tableList.map((listItem, key) => {
    return (
      <ListItem
        button
        key={key}
        className={selectedTable === key ? classes.selected : classes.default}
        onClick={() => selectOneTable(key)}
      >
        <ListItemIcon>
          <InsertDriveFileOutlinedIcon />
        </ListItemIcon>
        <Typography variant="body1" component="span">{listItem.name}</Typography>
      </ListItem>
    )
  })

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {listBody}
    </List>
  )
}

CombineList.propTypes = {
  tableList: PropTypes.array,
  selectedTable: PropTypes.number,
  selectOneTable: PropTypes.func
}

export default CombineList
