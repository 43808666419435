import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '16px!important',
    float: 'right'
  },
  onePoint: {
    height: theme.components.legend.size,
    margin: theme.spacing(0, 2),
    fontFamily: theme.components.legend.fontFamily,
    fontSize: theme.components.legend.fontSize
  },
  coloredCircle: {
    width: theme.components.legend.size,
    height: theme.components.legend.size,
    borderRadius: theme.components.legend.borderRadius,
    marginRight: theme.components.legend.marginRight
  }
}))

function ChartLegend () {
  const classes = useStyles()

  const legendData = [
    {
      name: 'Baseline',
      color: 'rgb(105,202,152)'
    },
    {
      name: 'Scenario',
      color: 'rgb(129,140,248)'
    }
  ]

  const legendBody = legendData.map((element, key) => {
    return (
        <Box key={key} className={classes.onePoint} display='flex' alignItems='center'>
          <div className={classes.coloredCircle} style={{ backgroundColor: element.color }} />
          <span>{element.name}</span>
        </Box>
    )
  })

  return (
    <Box className={classes.root} display='flex'>
      {legendBody}
    </Box>
  )
}

export default ChartLegend
