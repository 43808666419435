import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableHeader from './TableHeared'
import TablePagination from '@material-ui/core/TablePagination'
import CustomTableBody from './CustomTableBody'
import TableTitle from './TableTitle'

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: theme.typography.body1.fontWeight
  }
}))

function CustomTable ({ columns, data, tableName, onApply, addRow, duplicateRow, deleteRow, filterData }) {
  const classes = useStyles()
  const rowsData = []
  for (let i = 0; i < data.length; i++) {
    const oneRow = {}
    for (let j = 0; j < columns.length; j++) {
      oneRow[columns[j]] = data[i][j]
    }
    rowsData.push(oneRow)
  }

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(columns[0])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => { setPage(newPage) }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

  return (
    <div style={{ width: '100%' }}>
        <TableTitle title={tableName.name} handleSearch={filterData} counter={data.length}/>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeader columns={columns} order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>

            <CustomTableBody
               rowsData={data}
               order={order}
               orderBy={orderBy}
               page={page}
               rowsPerPage={rowsPerPage}
               emptyRows={emptyRows}
               handleEditCell={onApply}
               addRow={addRow}
               duplicateRow={duplicateRow}
               deleteRow={deleteRow}
            />

          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{ caption: classes.caption }}
        />
    </div>
  )
}

CustomTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  tableName: PropTypes.object,
  onApply: PropTypes.func,
  addRow: PropTypes.func,
  duplicateRow: PropTypes.func,
  deleteRow: PropTypes.func,
  filterData: PropTypes.func
}

export default CustomTable
