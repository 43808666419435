import React, { useCallback, useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import PropTypes from 'prop-types'
// eslint-disable-next-line camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

function LineChart (props) {
  const {
    containerId,
    data,
    config
  } = props

  const drawLineChart = useCallback(() => {
    if (!data.length) {
      return <></>
    }

    am4core.useTheme(am4themes_animated)

    // Create chart instance
    const chart = am4core.create(containerId, am4charts.XYChart)

    // Add data
    chart.data = data

    // chart.legend = new am4charts.Legend()
    // chart.legend.position = 'right'

    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'name'
    categoryAxis.renderer.grid.template.opacity = 0

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.min = 0
    valueAxis.renderer.grid.template.opacity = 0
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5
    valueAxis.renderer.ticks.template.stroke = am4core.color('#495C43')
    valueAxis.renderer.ticks.template.length = 10
    valueAxis.renderer.line.strokeOpacity = 1
    valueAxis.renderer.baseGrid.disabled = true
    valueAxis.renderer.minGridDistance = 50
    valueAxis.calculateTotals = true
    valueAxis.numberFormatter.numberFormat = '#a'

    // Create series
    function createSeries (field, name, color) {
      const series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueX = field
      series.dataFields.categoryY = 'name'
      series.stacked = true
      series.fill = color
      series.stroke = color
      series.name = name
      series.columns.template.tooltipText = '[bold]{valueX}[/]'
      series.tooltip.pointerOrientation = 'vertical'

      return series
    }

    // some space needed for the total label
    categoryAxis.renderer.labels.template.marginRight = 0
    // this is the total label series, it is invisible
    const series = createSeries('empty', 'empty')
    // hidden in legend
    series.hiddenInLegend = true
    // bullets can't be masked
    series.maskBullets = false

    Object.keys(config).forEach(key => {
      const item = config[key]
      createSeries(key, item.label, item.color)
    })
  }, [containerId, data])

  useEffect(() => {
    drawLineChart()
  }, [containerId, data])

  return (
      <div className='chart-container' id={containerId} />
  )
}

LineChart.propTypes = {
  containerId: PropTypes.string,
  data: PropTypes.array,
  config: PropTypes.object
}

export default React.memo(LineChart, (props, nextProps) => {
  return JSON.stringify(props.data) === JSON.stringify(nextProps.data)
})
