import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, withStyles } from '@material-ui/core'
import { InsertChart, TableChart } from '@material-ui/icons'
import { setState, SET_INPUT_VIEW_TYPE } from '../../redux/actions/InputActions'
import { useSelector, useDispatch } from 'react-redux'

const styles = (theme) => ({
  default: {
    marginLeft: theme.spacing(3),
    '& > *': {
      width: 36,
      height: 36
    }
  },
  buttonChecked: {
    color: theme.palette.primary.lighten1,
    backgroundColor: theme.palette.primary.lighten2
  },
  buttonNotChecked: {
    color: 'rgba(0,0,0,0.54)'
  }
})

const SwitchType = ({ classes }) => {
  const inputData = useSelector(state => state.InputReducer)
  const {
    viewType
  } = inputData

  const dispatch = useDispatch()

  const toggleButton = (prop) => {
    dispatch(setState(SET_INPUT_VIEW_TYPE, prop))
  }

  let classForChart, classForTable
  if (viewType === 'summary') {
    classForChart = classes.buttonChecked
    classForTable = classes.buttonNotChecked
  } else {
    classForChart = classes.buttonNotChecked
    classForTable = classes.buttonChecked
  }

  return (
    <ButtonGroup classes={{ root: classes.default }}>
      <Button className={classForChart} onClick={() => toggleButton('summary')}>
        <InsertChart/>
      </Button>
      <Button className={classForTable} onClick={() => toggleButton('baseline')}>
        <TableChart/>
      </Button>
    </ButtonGroup>
  )
}

SwitchType.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SwitchType)
