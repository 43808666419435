const initialState = {
  navigation: [{
    id: 0,
    value: 1,
    name: 'Initial Input',
    validated: true,
    href: '/input'
  }, {
    id: 1,
    value: 2,
    name: 'Baseline Output',
    validated: false,
    href: '/output'
  }, {
    id: 2,
    value: 3,
    name: 'Scenario Output',
    validated: false,
    href: '/scenario'
  }]
}

export default function NavigationReducer (state = initialState, action) {
  switch (action.type) {
    case 'CHANGE': {
      return {
        ...state,
        navigation: action.value
      }
    }
    default:
      return state
  }
}

export { initialState }
