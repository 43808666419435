import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../redux/actions/AuthActions'
import env from 'react-dotenv'
import './index.css'
import Home from '../screens/Home'
import Input from '../screens/Input'
import GlobalPanel from '../components/GlobalPanel/GlobalPanel'

import Output from '../screens/Output'
import Scenario from '../screens/Scenario'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

const App = () => {
  const dispatch = useDispatch()
  const authData = useSelector(state => state.AuthReducer)

  useEffect(() => {
    dispatch(login({
      email: env.AUTH_USER_EMAIL,
      password: env.AUTH_USER_PASSWORD
    }))
  }, [])

  if (!authData.apiKey) {
    return <></>
  }

  return (
    <React.Fragment>
        <div style={{ display: 'flex', width: '100%' }} className='full_content'>
          <GlobalPanel/>
          <div style={{ flex: 1 }}>
            <Router>
              <div style={{ backgroundColor: '#f5f5f5', height: '100%' }}>
                <Switch>
                  <Route path="/Input">
                    <Input/>
                  </Route>
                  <Route path="/Output">
                    <Output />
                  </Route>
                  <Route path="/Scenario">
                    <Scenario />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </div>
            </Router>

          </div>

        </div>

    </React.Fragment>
  )
}

export default App
