import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Founders-Regular',
      'Danzza',
      'Danzza-Regular',
      'Danzza-Bold',
      'Danzza-Light'
    ].join(','),
    h1: {
      fontFamily: 'Danzza-Regular',
      fontSize: '1.8rem'
    },
    h2: {
      fontFamily: 'Danzza-Bold',
      fontSize: '1.5rem'
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: 700
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 300
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 500
    },
    subtitle1: {
      fontSize: '0.9rem',
      letterSpacing: '0px',
      fontWeight: 600
    },
    subtitle2: {
      fontSize: 12
    },
    body1: {
      fontSize: '1.1rem',
      'padding-right': '5px'
    },
    body2: {
      fontFamily: 'Founders-Bold',
      fontSize: '1.1rem'
    },
    button: {
      fontFamily: 'Founders-Mono-Regular',
      fontSize: '1rem',
      fontWeight: 600,
      letterSpacing: '2.3px'
    }
  },
  spacing: [0, 6, 12, 24, 48],
  palette: {
    primary: {
      main: '#0C0A5A',
      contrastText: '#ffffff',
      lighten1: '#616FF6',
      lighten2: 'rgba(97,111,246,0.1)'
    },
    secondary: {
      main: '#44BD7E',
      contrastText: '#ffffff'
    },
    secondaryBackground: {
      buttonBorder: '#9E9E9E',
      cards: '#F5F5F5'
    },
    action: {
      disabledBackground: 'rgb(224,249,239)',
      disabled: 'rgba(0,0,0,0.38)'
    }
  },
  components: {
    header: {
      backgroundColor: '#ffffff'
    },
    tab: {
      width: 200,
      height: 100
    },
    navigatioIcons: {
      size: 24,
      wideSize: 30,
      inactiveColor: '#C0CCDD',
      activeColor: '#616FF6',
      color: '#ffffff',
      fontSize: 12,
      wideFontSize: 18,
      borderRadius: '50%',
      lineHeight: '24px'
    },
    logtype: {
      width: 47,
      height: 38
    },
    titleBlock: {
      height: 44
    },
    graphs: {
      minHeight: 350
    },
    metrics: {
      width: 400,
      costLineHeight: '30px',
      oneTabWidth: 100
    },
    legend: {
      fontFamily: 'Founders-Regular',
      fontSize: '0.9rem',
      size: 8,
      borderRadius: '50%',
      marginRight: 4,
      mapLegendHeight: '24px!important'
    },
    filtersPopover: {
      width: 225,
      indicatorSize: 5,
      indicatorColor: '#5699FF',
      indicatorBorderRadius: '50%',
      indicatorPosition: 10
    },
    inventoryChart: {
      height: 400
    },
    modals: {
      width: 480,
      height: 250
    }
  }
})

export { theme }
