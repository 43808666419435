import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

function TableHeader ({ order, orderBy, onRequestSort, columns }) {
  const columnsArray = []
  for (let i = 0; i < columns.length; i++) {
    columnsArray.push({
      id: i,
      numeric: true,
      disablePadding: i === 0,
      label: columns[i]
    })
  }

  const createSortHandler = (property) => (event) => { onRequestSort(property) }

  const headerBody = () => {
    const result = []
    for (let i = 0; i < columnsArray.length; i++) {
      if (i === 0) {
        result.push(
          <TableCell
            key={i}
            align='left'
            sortDirection={orderBy === columnsArray[i].label ? order : false}
          >
            <TableSortLabel
              active={orderBy === columnsArray[i].label}
              direction={orderBy === columnsArray[i].label ? order : 'asc'}
              onClick={createSortHandler(columnsArray[i].label)}
            >
              {columnsArray[i].label}
            </TableSortLabel>
          </TableCell>
        )
      } else {
        result.push(
          <TableCell
            key={i}
            align='left'
            sortDirection={orderBy === columnsArray[i].label ? order : false}
          >
            <TableSortLabel
              active={orderBy === columnsArray[i].label}
              direction={orderBy === columnsArray[i].label ? order : 'asc'}
              onClick={createSortHandler(columnsArray[i].label)}
            >
              {columnsArray[i].label}
            </TableSortLabel>
          </TableCell>
        )
      }
    }
    return result
  }

  return (
      <TableHead>
        <TableRow>
          {headerBody()}
        </TableRow>
      </TableHead>
  )
}

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
}

export default TableHeader
