import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(() => ({
  root: {
    width: 'calc(100vw - 90px)'
  }
}))

function FlexDataContainer ({ children }) {
  const classes = useStyles()

  return (
    <Box className={classes.root} display='flex'>
      {children}
    </Box>
  )
}

FlexDataContainer.propTypes = {
  children: PropTypes.array
}

export default FlexDataContainer
