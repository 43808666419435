/* eslint-disable react/jsx-key */
import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decimalNumber } from '../../services/Data/ServiceDataHelpers'
import PropTypes from 'prop-types'
import { setMaps } from '../../redux/actions/MapActions'
import * as helpers from '@turf/helpers'
import bezierSpline from '@turf/bezier-spline'

import L from 'leaflet'
require('leaflet.sync')
require('@elfalem/leaflet-curve')
require('leaflet-polylinedecorator')

const BaseIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    popupAnchor: [0, 0]
  }
})

const icons = {
  orange: new BaseIcon({ iconUrl: 'data:image/svg+xml,%3Csvg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="orange" cx="11" cy="11" r="9" stroke="black" stroke-width="2"/%3E%3C/svg%3E' }),
  blue: new BaseIcon({ iconUrl: 'data:image/svg+xml,%3Csvg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="blue" cx="11" cy="11" r="9" stroke="black" stroke-width="2"/%3E%3C/svg%3E' }),
  green: new BaseIcon({ iconUrl: 'data:image/svg+xml,%3Csvg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="green" cx="11" cy="11" r="9" stroke="black" stroke-width="2"/%3E%3C/svg%3E' }),
  purple: new BaseIcon({ iconUrl: 'data:image/svg+xml,%3Csvg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="purple" cx="11" cy="11" r="9" stroke="black" stroke-width="2"/%3E%3C/svg%3E' }),
  black: new BaseIcon({ iconUrl: 'data:image/svg+xml,%3Csvg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="black" cx="11" cy="11" r="9" stroke="black" stroke-width="2"/%3E%3C/svg%3E' })
}

const Map = props => {
  const {
    containerId,
    syncId = null,
    showArcs = false,
    coordinates = [],
    lines = []
  } = props

  const dispatch = useDispatch()

  const [myMap, setMyMap] = useState(null)

  const mapData = useSelector(state => state.MapReducer)

  const updateMapsState = (id, map) => {
    const maps = mapData.maps
    maps[containerId] = map

    if (maps !== mapData.maps) {
      dispatch(setMaps(maps))
    }
  }

  const drawMap = useCallback(() => {
    const decorators = []
    if (myMap) {
      myMap.decorators.forEach(decorator => {
        myMap.removeLayer(decorator)
      })
    }

    const _myMap = myMap || L.map(containerId).setView([40.71435, -74.00597], coordinates.length ? 13 : 2)

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(_myMap)

    const mapCoordinates = typeof coordinates === 'undefined' || !coordinates.length ? [{ latitude: 40.71435, longitude: -74.00597, name: '', color: '#000000' }] : coordinates

    if (coordinates.length) {
      const markersLatLong = mapCoordinates.map(item => {
        const latLong = L.latLng(item.latitude, item.longitude)
        const marker = L.marker(latLong, { icon: icons[item.color] }).bindTooltip(`${item.name}<br/>Distance: ${decimalNumber(Number(item.distance))} miles<br/>Parts: ${item.parts}`)
          .addTo(_myMap)
        decorators.push(marker)
        return latLong
      })

      lines.forEach(item => {
        const src = item[0]
        const dst = item[1]

        const offsetX = dst.longitude - src.longitude
        const offsetY = dst.latitude - src.latitude

        const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2))
        const theta = Math.atan2(offsetY, offsetX)

        const thetaOffset = (3.14 / 10)

        const r2 = (r / 2) / (Math.cos(thetaOffset))
        const theta2 = theta + thetaOffset

        const arciness = 0.7 // adjust the ammount of arc in the lines here
        const midpointX = ((r2 * Math.cos(theta2)) * arciness) + src.longitude
        const midpointY = ((r2 * Math.sin(theta2)) * arciness) + src.latitude

        const { color } = src

        // const grandArc1 = L.curve(['M', [src.latitude, src.longitude], 'Q', [midpointY, midpointX], [dst.latitude, dst.longitude]], { color: color, weight: 3 })
        //   .bindTooltip(`From: ${src.name}<br/> To: ${dst.name}<br/> Delivery route, miles: ${decimalNumber(Number(src.distance))}`)
        //   .addTo(_myMap)
        // decorators.push(grandArc1)
        // console.log(1, grandArc1)
        // -----------------------------------------------

        const line = helpers.lineString([[src.latitude, src.longitude], [midpointY, midpointX], [dst.latitude, dst.longitude]])
        // console.log('line', line)
        const lineNext = bezierSpline(line, { resolution: 10000 })
        // console.log('line next:', bezierSpline(line))
        // const grandArc2 = bezierSpline(line)
        //   .addTo(_myMap)
        // console.log(2, grandArc2)
        // decorators.push(grandArc)
        const grandArc = L.polyline(lineNext.geometry.coordinates, { color: color, weight: 1 })
          .bindTooltip(`From: ${src.name}<br/> To: ${dst.name}<br/> Delivery route, miles: ${decimalNumber(Number(src.distance))}`)
          .addTo(_myMap)
        decorators.push(grandArc)
        // console.log(grandArc)
        //   .bindTooltip(`From: ${src.name}<br/> To: ${dst.name}<br/> Delivery route, miles: ${decimalNumber(Number(src.distance))}`)
        //   .addTo(_myMap)
        // decorators.push(grandArc)
        // console.log(2, grandArc)

        // let lineString = L.polyline(lineCoordinates,{color: 'black'});

        // var buffered = turf.buffer(lineString, 500, {units: 'miles'});
        // var bufferedLayer = L.geoJSON(null);
        // bufferedLayer.addData(buffered);
        // bufferedLayer.addTo(map);

        const vector = L.polylineDecorator(grandArc, {
          patterns: [
            { offset: '50%', repeat: '100%', symbol: L.Symbol.arrowHead({ pixelSize: 18, headAngle: 35, pathOptions: { color: color, fillOpacity: 1, weight: 0 } }) }
          ]
        })
          .bindTooltip(`From: ${src.name}<br/> To: ${dst.name}<br/> Delivery route, miles: ${decimalNumber(Number(src.distance))}`)
          .addTo(_myMap)

        decorators.push(vector)
      })

      if (!myMap) {
        _myMap.fitBounds(markersLatLong)
      }
    }

    updateMapsState(containerId, _myMap)

    if (syncId && typeof mapData.maps[syncId] !== 'undefined') {
      const syncMap = mapData.maps[syncId]
      _myMap.sync(syncMap)
      syncMap.sync(_myMap)
    }

    _myMap.decorators = decorators

    setMyMap(_myMap)
  }, [containerId, coordinates, showArcs, lines, mapData.maps])

  useEffect(() => {
    try {
      drawMap()
    } catch (e) {}
  }, [containerId, coordinates, showArcs, lines, mapData.maps])

  return (
    <React.Fragment>
      <div className='chart-container' id={containerId}/>
    </React.Fragment>
  )
}

Map.propTypes = {
  containerId: PropTypes.string,
  syncId: PropTypes.string,
  showArcs: PropTypes.bool,
  coordinates: PropTypes.array,
  lines: PropTypes.array
}

export default React.memo(Map, (props, nextProps) => {
  return JSON.stringify(props) === JSON.stringify(nextProps)
})
