import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: theme.palette.secondaryBackground.cards
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  textAlignhed: {
    textAlign: 'end',
    lineHeight: theme.components.metrics.costLineHeight
  },
  margined: {
    marginRight: theme.spacing(2)
  }
}))

function Cost ({ children, costs, isSetFilter }) {
  const classes = useStyles()

  const cardsBody = costs.map((onePrice, key) => {
    const priceToCheck = parseFloat(onePrice.price.toString().replace('$', ''))
    const scenarioPriceToCheck = typeof onePrice.scenarioPrice !== 'undefined' ? parseFloat(onePrice.scenarioPrice.toString().replace('$', '')) : 0

    if (!Math.ceil(priceToCheck) && !Math.ceil(scenarioPriceToCheck)) {
      return null
    }

    return (
      <ListItem key={key}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography variant="body1" component="span">{onePrice.name}</Typography>

              <div className={classes.textAlignhed}>
                <Typography variant="body2" component="span" className={classes.margined}>{onePrice.price}</Typography>
                { isSetFilter ? <Typography variant="body1" component="span">{onePrice.percent}%</Typography> : null }

                {
                  onePrice.scenarioPrice
                    ? <React.Fragment>
                      <Divider/>
                      <Typography variant="body2" component="span" className={classes.margined}>{onePrice.scenarioPrice}</Typography>
                      { isSetFilter ? <Typography variant="body1" component="span">{onePrice.scenarioPercent}%</Typography> : null }
                    </React.Fragment>
                    : <></>
                }
              </div>
            </Box>
          </CardContent>
        </Card>
      </ListItem>
    )
  })

  return (
    <div>
      {children}
      <div className={classes.cardsContainer}>
        <List component="ul" aria-label="main mailbox folders">
          {cardsBody}
        </List>
      </div>
    </div>
  )
}

Cost.propTypes = {
  children: PropTypes.object,
  costs: PropTypes.array,
  isSetFilter: PropTypes.bool
}

export default React.memo(Cost, (props, nextProps) => {
  if (JSON.stringify(props.costs) === JSON.stringify(nextProps.costs)) {
    return true
  }
})
