import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage'

const persistConfig = {
  key: 'root',
  storage: storage('optilogic-persist-db'),
  // blacklist: [],
  whitelist: ['JobReducer', 'OutputReducer', 'InputReducer', 'FilterReducer', 'ScenarioReducer', 'NavigationReducer', 'TimerReducer']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  const store = createStore(
    persistedReducer,
    applyMiddleware(
      thunkMiddleware
    )
  )
  const persistor = persistStore(store)
  return { store, persistor }
}
