import { SET_AUTH_TOKEN } from '../actions/AuthActions'

const initialState = {
  apiKey: ''
}

export default function InputReducer (state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        apiKey: action.value
      }
    default:
      return state
  }
}
