import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/Header/Header'
import TopBlock from '../components/Blocks/TopBlock'
import CombineMetrics from '../components/BaselineSummary/CombineMetrics'
import CombineGraphs from '../components/BaselineSummary/CombineGraphs'
import Map from '../components/Map/Map'
import Chart from '../components/Chart/Chart'
import FiltersAndExport from '../components/FiltersAndExport/FiltersAndExport'
import Cost from '../components/OutputMetricsDataComponents/Cost'
import Risk from '../components/OutputMetricsDataComponents/Risk'
import Mode from '../components/OutputMetricsDataComponents/Mode'
import Legend from '../components/OutputMetricsDataComponents/Legend'
import ViewOutputMetrics from '../components/ViewOutputMetrics/ViewOutputMetrics'
// import RoundChart from '../components/RoundChart/RoundChart'
import LineChart from '../components/LineChart/LineChart'
import Data from '../services/Data'
import { useHistory } from 'react-router-dom'
import ModalChooseScenario from '../components/Modals/ModalChooseScenario'
import CustomButton from '../components/Buttons/CustomButton'
import Timeline from '@material-ui/icons/Timeline'
import Timer from '../components/Timer/Timer'
import { selectScenario } from '../redux/actions/ScenarioActions'
import { setState as setJobState, SET_TYPE } from '../redux/actions/JobActions'
import exportCSV from '../services/Data/ExportDataServices'
// import Typography from '@material-ui/core/Typography'
import { setAlternativeSuppliers, checkIsSetFilter } from '../redux/actions/FiltersActions'
import MapLegend from '../components/Map/MapLegend'
import ArcSwitcher from '../components/ArcSwitcher/ArcSwitcher'
import Box from '@material-ui/core/Box'

function Output () {
  const dataSet = Data()

  const outputData = useSelector(state => state.OutputReducer)
  const inputData = useSelector(state => state.InputReducer)
  const jobData = useSelector(state => state.JobReducer)
  const scenarioData = useSelector(state => state.ScenarioReducer)
  const filtersData = useSelector(state => state.FilterReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!inputData.input.length) {
      history.push('/input')
    }
  })

  const history = useHistory()

  useEffect(() => {
    dataSet.setOutputData()
    dataSet.setScenarios()
  }, [])

  const [showArcs, setShowArcs] = useState(false)
  const [open, setOpen] = useState(false)
  const [timerIsStarted, setTimerIsStarted] = useState(false)

  const { jobStatus, currentJob, jobKey } = jobData
  const jobType = jobData.type

  useEffect(() => {
    if (jobKey) {
      dataSet.stopTimer()
      dataSet.startTimer()
      setTimerIsStarted(true)
    }
  }, [jobKey])

  useEffect(() => {
    if (jobStatus && jobKey && jobType === 2) {
      const action = () => {
        dataSet.setScenario().then(() => {
          dataSet.setNavigationStateGoNext(2, () => {
            setTimeout(() => {
              history.push('/scenario')
            }, 1000)
          })
        })
      }
      dataSet.checkJob(action)
    }
  }, [jobStatus, jobKey])

  useEffect(() => {
    if (jobStatus) {
      const action = () => {
        dataSet.startJob()
      }
      dataSet.updateScenarioConfig(scenarioData.current, action)
    }
  }, [jobStatus, currentJob])

  useEffect(() => {
    dataSet.Output.setFilters()
  }, [outputData.output])

  const changeShowArcs = () => { setShowArcs(!showArcs) }
  const handleOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  const {
    coordinates,
    lines
  } = dataSet.Output.map(showArcs)

  const runScenario = scenario => {
    dataSet.resetScenario()
    dataSet.resetNavigation(1)
    dispatch(setJobState(SET_TYPE, 2))
    dispatch(selectScenario(scenario))
    dataSet.updateScenarioConfig(scenario, dataSet.startJob)
  }

  if (!outputData.output.length) {
    return <></>
  }

  return (
    <React.Fragment>
        <Header>
          <React.Fragment>
            {
              timerIsStarted
                ? <Timer stopHandler={() => { setTimerIsStarted(false) }} />
                : <CustomButton
                      title='Run Scenario'
                      action={handleOpen}
                      colored
                  >
                    <Timeline />
                  </CustomButton>
            }

            <ModalChooseScenario
              title='Run Scenario'
              scenarios={dataSet.scenarios}
              runFunction={runScenario}
              open={open}
              closeFunction={handleClose}
            />
          </React.Fragment>
        </Header>
        <TopBlock title='Baseline Output'>
          <FiltersAndExport exportFunc={() => exportCSV({ ...outputData, ...{ input: inputData.input } })} />
        </TopBlock>
        <Box display='flex'>
          <CombineMetrics title='Metrics'>
            <ViewOutputMetrics>
              {[
                {
                  tabName: 'Cost',
                  tabBody: <Cost costs={dataSet.Output.costs()} isSetFilter={checkIsSetFilter(filtersData.filters)}>
                    <div>
                      <Legend data={dataSet.Output.costsChartDataLegend()} justify='flex-end' cuted={false}/>
                      <LineChart config={dataSet.Output.costsChartData().config} data={dataSet.Output.costsChartData().data} containerId='chartdiv4'/>
                    </div>
                  </Cost>
                },
                {
                  tabName: 'Mode',
                  tabBody: <div>
                    <Legend data={dataSet.Output.modesChartLegend()} justify='flex-end' cuted={false}/>
                      {
                          dataSet.Output.modesChartData().map((mode, key) =>
                              <Mode key={key} title={mode.title} chartName={mode.chartName}
                                    config={mode.config} data={mode.data} containerId={mode.containerId} />
                          )
                      }
                  </div>
                },
                {
                  tabName: 'Risk',
                  tabBody: <Risk risks={dataSet.Output.risks()} isSetFilter={checkIsSetFilter(filtersData.filters)}/>
                }
              ]}
            </ViewOutputMetrics>
          </CombineMetrics>
          <CombineGraphs
              arcSwitcher={
                <ArcSwitcher
                  checkedShow={showArcs}
                  changeShowArcs={changeShowArcs}
                  checkedAlternative={filtersData.showAlternativeSuppliers}
                  changeAlternative={() => { dispatch(setAlternativeSuppliers((!filtersData.showAlternativeSuppliers))) }}
                />
              }
              map={
                <div>
                  <MapLegend/>
                  <Map showArcs={showArcs} coordinates={coordinates} lines={lines} containerId="chartdiv" />
                </div>
              }
              leftChart={
                {
                  title: 'Supplier Distance Histogram',
                  content: <Chart key='1' titles={{ left: 'Suppliers', bottom: 'Distance band' }} data={dataSet.Output.suppliersWithBandOfDistance()} containerId="chartdiv2" />
                }
              }
              rightChart={
                {
                  title: 'Parts per Supplier Histogram',
                  content: <Chart key='1' titles={{ left: 'Suppliers', bottom: 'Parts quantity band' }} data={dataSet.Output.suppliersWithPartQuantityBand()} containerId="chartdiv3" />
                }
              }
          />
      </Box>
    </React.Fragment>
  )
}

export default Output
