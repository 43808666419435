import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  focusedCell: {
    height: 24,
    padding: 8,
    borderRadius: 5,
    backgroundColor: 'rgba(97,111,246,0.1)',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  cellEdited: {
    paddingLeft: 0,
    paddingTop: 3,
    paddingBottom: 3,
    position: 'sticky'
  },
  cellDefault: {
    position: 'sticky'
  },
  textfield: {
    position: 'absolute',
    top: 3
  }
}))

const EditableRow = ({ value, onApply, cellIndex, rowIndex, columnName }) => {
  const classes = useStyles()
  const wrapperRef = useRef(null)

  const [isOpenInput, setOpenInput] = useState(false)
  const [rowValue, setRowValue] = useState(value)
  const [newValue, setNewValue] = useState(rowValue)
  const [isFocused, setFocusing] = useState(false)

  if (value !== rowValue) { setRowValue(value) }
  const handleKeyDown = event => {
    setNewValue(event.currentTarget.value)
  }

  const onClickHandled = () => {
    setOpenInput(true)
  }

  const applyValue = (event, cellIndex, rowIndex) => {
    event.stopPropagation()
    onApply(cellIndex, rowIndex, newValue)
    setOpenInput(false)
    setFocusing(false)
  }

  const reset = event => {
    event.stopPropagation()
    setNewValue('')
    setOpenInput(false)
    setFocusing(false)
  }

  const handleFocus = () => {
    setFocusing(true)
  }

  const handleClickOutside = (event) => {
    if (isFocused && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setFocusing(false)
    }
    if (isOpenInput) {
      const parent = event.target.parentNode
      const container = document.querySelector('#cell-edit')
      if (!container.contains(parent)) {
        setOpenInput(false)
        setFocusing(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const controlPanel =
      <React.Fragment>
        <CloseRoundedIcon onClick={reset} fontSize='small' style={{ cursor: 'pointer' }}/>
        <CheckRoundedIcon onClick={(event) => applyValue(event, cellIndex, rowIndex)} fontSize='small' style={{ cursor: 'pointer' }}/>
      </React.Fragment>

  return isOpenInput
    ? <TableCell className={ classes.cellEdited } key={cellIndex} component='th' scope='row' align="left" id='cell-edit' >
        <TextField
          className={ classes.textfield }
          defaultValue={rowValue}
          onChange={handleKeyDown}
          variant="outlined"
          size='small'
          InputProps={{ endAdornment: controlPanel }}
        />
      </TableCell>

    : <TableCell
        key={cellIndex}
        component='th'
        scope='row'
        align="left"
        onClick={handleFocus}
        style={{ padding: isFocused ? 3 : 8 }}
        className={ classes.cellDefault }
      >
        <div
          className={isFocused ? classes.focusedCell : null}
          style={{ justifyContent: rowValue === '' ? 'flex-end' : 'space-between' }}
        >
          <Typography variant="body1" component="span">{rowValue}</Typography>
        {
          isFocused
            ? <IconButton onClick={onClickHandled} size='small' ref={wrapperRef}>
                <EditOutlinedIcon className='editOneCell'/>
              </IconButton>
            : null
        }
        </div>
      </TableCell>
}

EditableRow.propTypes = {
  value: PropTypes.string,
  onApply: PropTypes.func,
  cellIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  columnName: PropTypes.string
}

export default EditableRow
