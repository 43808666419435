import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ChartLegend from '../Chart/ChartLegend'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(3),
    flex: 1
  },
  ratio: {
    position: 'relative',
    height: 0,
    border: 'none'
  },
  ratioMap: {
    paddingTop: '50%'
  },
  ratioGraph: {
    paddingTop: '33%'
  },
  container: {
    marginBottom: theme.spacing(3),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  map: {
    height: 'calc(100% - 128px)',
    padding: '0px!important',
    margin: theme.spacing(3),
    '& div': {
      height: '100%'
    }
  },
  graphContainer: {
    flex: 1,
    minHeight: theme.components.graphs.minHeight
  },
  graphContent: {
    height: 'calc(100% - 104px)',
    padding: '0!important',
    margin: theme.spacing(3)
  },
  title: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight
  },
  cardHeader: {
    padding: theme.spacing(0),
    margin: theme.spacing(3, 3, 0, 3)
  }
}))

function CombineGraphs ({ arcSwitcher, map, leftChart, rightChart, inventoryChart }) {
  const classes = useStyles()

  return (

    <div className={classes.root}>
      <div className={`${classes.ratio} ${classes.ratioMap}`}>
        <Card className={classes.container}>
          <CardHeader
            title="Network"
            titleTypographyProps={{
              className: classes.title
            }}
            action={ arcSwitcher }
            className={ classes.cardHeader }
          />
          <CardContent className={classes.map}>
            <div>
              {map}
            </div>
          </CardContent>
        </Card>
      </div>

      <div className={`${classes.ratio} ${classes.ratioGraph}`}>
        <Box display='flex' flexDirection='row' className={classes.container}>
            <Card className={classes.graphContainer} style={{ marginRight: 24 }}>
              <CardHeader
                className={classes.cardHeader}
                title={leftChart.title}
                titleTypographyProps={{
                  className: classes.title
                }}
              />
              <CardContent className={classes.graphContent}>
                  {
                    leftChart.legend
                      ? <ChartLegend/>
                      : <></>
                  }
                  {leftChart.content}
              </CardContent>
            </Card>

            <Card className={classes.graphContainer}>
              <CardHeader
                title={rightChart.title}
                titleTypographyProps={{
                  className: classes.title
                }}
                className={classes.cardHeader}
              />
              <CardContent className={classes.graphContent}>
                {
                    rightChart.legend
                      ? <ChartLegend/>
                      : <></>
                  }
                  {rightChart.content}
              </CardContent>
            </Card>
        </Box>

      </div>
      { inventoryChart || null }
    </div>
  )
}

CombineGraphs.propTypes = {
  arcSwitcher: PropTypes.object,
  map: PropTypes.object,
  leftChart: PropTypes.object,
  rightChart: PropTypes.object,
  inventoryChart: PropTypes.object
}

export default CombineGraphs
